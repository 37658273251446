/**
 * 车辆线路管理
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  SlideDrager,
  PopUp,
  PopTip,
  OrgSug,
  Label,
  PureInput as Input,
  TextArea,
  Button,
  Select,
  Icon,
  Address,
  Fence,
  Switch,
  Tips,
  ModalDialog,
  Flex,
  SelectDrop,
  UploadFile,
} from 'components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CHECK, WARN, regulars, ERROR, FLOAT_P_1 } from 'constants';
// import actions from 'actions'
// import _ from 'lodash'
// import baseDataSug from 'utils/sug/baseData'
// import projectSug, { projectHeader } from 'utils/sug/project'
import {
  formatObjectToArray,
  confirmWithReason,
  validateFieldsOf,
  showInfo,
  formatFenceData,
  fetchApi as fetch,
  formatCamelCase,
  buildTipMixin,
} from 'utils';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
// import { errmsgTip } from 'components/commoncomponents/listOperation/newTips'

import {
  orgFetchApi,
  orgHeader,
  sugUrl,
  effect_radius_tip,
  lineType2SetKey,
  customerProjHeader,
  carrierProjHeader,
  carrierHeader,
  contractHeader,
  projectHeader,
  defGoods,
  ceeAndCorSug,
  ceeAndCorHeader,
  TAG_ENUM,
} from './constants';
import { getCardHeaderClass, getCardBodyClass, handleFieldSet } from './tools';
import { withPopUpI18n, defaultI18n } from '@/utils/i18n/context';
import _ from 'lodash';
import RouteCard from './components/RouteCard';
import './index.scss';
import DaoHangTip from './components/DaoHangTip/index';

const addNodeTip = buildTipMixin('添加经停点', { dir: 'right' });

// 记录字段变更key
const SET_CHANGE_TAG = {
  node_address: TAG_ENUM.address,
  node_name: TAG_ENUM.address,
  addr_remark: TAG_ENUM.addrRemark,
};

class LineOperat extends PureComponent {
  static defaultProps = {
    classname: 'line-operat',
    parm: {
      isShow: false, // 初始化 不加载
      pageType: 'carriersMangerList',
      editType: 'add',
      titleName: '新增车辆线路管理',
    },
    autoDestroy: true,
    i18n: defaultI18n,
    g7Map: false,
  };
  static propTypes = {
    classname: PropTypes.string,
    slideWidth: PropTypes.number,
    slideTitle: PropTypes.string,
    driverOperat: PropTypes.object,
    titleName: PropTypes.string,
    driverOperatState: PropTypes.bool,
    dataFromBackend: PropTypes.object,
    _oldData: PropTypes.object, // 后台给的默认值
    _postData: PropTypes.object, // 点击保存时，给后台的数据
    comId: PropTypes.number,
    opType: PropTypes.string,
    close: PropTypes.func,
    togglePopWidth: PropTypes.func,
    popContainer: PropTypes.object,
    parm: PropTypes.object, // 所有传递来的参数
    dataAM: PropTypes.object,
    that: PropTypes.object,
    isLoading: PropTypes.bool,
    submitCb: PropTypes.func, // 客户管理添加后的回调
    g7Map: PropTypes.bool,
  };

  constructor(prop) {
    super(prop);
    console.log(prop, 'prop');
    this.routeCardRef = React.createRef();
    prop.getIsEdited(this);
    this.componentEdited = false;
    this.hour_rule_value =
      window.company_setting && window.company_setting.hour_rule && window.company_setting.hour_rule.value;
    const lineInfo = prop.dataAM.line_info;
    // 因为后面的修改都是直接操作的对象，这里用个深拷贝保存下原始车线节点信息
    this.oldLineNodes = _.cloneDeep(lineInfo.line_nodes);
    let postInfo = lineInfo.line_nodes || [];
    const { head_id, line_distance = 0, line_num, goods_list, line_type } = lineInfo;
    const { company_id = window.company_id, use_corp_type = 2 } = lineInfo;
    const { line_name_edited, line_distance_edited, line_num_editable } = lineInfo;
    const { project_id } = lineInfo;
    const enumenum = prop.dataAM.enum;
    let lineObj = {
      ...lineInfo,
      company_id,
      use_corp_type,
      line_num_system: line_num,
      line_name: lineInfo.line_name,
      line_distance,
      carrier_id: lineInfo.carrier_id,
    };
    // 项目名称
    if (project_id) {
      lineObj.project_id = project_id.length ? project_id : [project_id];
    }
    if (prop.parm.opType === 'add') {
      // 新增线路
      const defualtNode = head_id ? enumenum.head_id[head_id] : {};
      const nodeName = defualtNode.short_name || '';
      let address = defualtNode.address || '{}';
      // 围栏信息
      const fenceObj = formatFenceData(defualtNode.fence);
      if (typeof address === 'string') {
        address = JSON.parse(address);
      }
      postInfo = [
        {
          node_name: nodeName,
          node_type: 7,
          node_address: address,
        },
        {
          node_name: '',
          node_type: 7,
        },
      ];
      lineObj = {
        ...lineObj,
        line_name: `${nodeName}->`,
        line_nodes_num: postInfo.length - 1,
      };
    } else if (!line_name_edited) {
      const line_name = this.setLineName(postInfo);
      lineObj = {
        ...lineObj,
        line_name,
      };
    }

    this.line_name_edited = line_name_edited;
    this.line_distance_edited = line_distance_edited;
    this.line_num_editable = line_num_editable;
    // 后端返回的节点类型枚举的全量
    this.nodeTypesEnumAll = Object.entries(enumenum.node_type).map(([key, item]) => ({ key, name: item.display }));

    // ============================== 枚举 ==============================
    const nodeTypesEnum = this.getNodeTypeEnum(+line_type);
    const companyIdEnum = enumenum.company_id
      .filter(item => +item.id === +company_id)
      .map(item => ({ key: item.id, name: item.display }));
    const lineTypeEnum = Object.entries(enumenum.line_type).map(([key, item]) => ({ key, name: item.display }));
    // const lTrLengthEnum = Object.keys(enumenum.l_tr_length || {}).map(key => ({ key, name: key }))
    // const lTrTypeEnum = Object.keys(enumenum.l_tr_type || {}).map(key => ({ key, name: key }))
    // const projectEnum = Object.keys(enumenum.project_id || {}).map(key => ({ id: key, proj_name: _.get(enumenum, `project_id.${key}.project_name`, '') }))
    // const customerProjEnum = customer_proj_id && customer_proj_name ? [{ id: customer_proj_id, proj_name: customer_proj_name }] : []
    const projectEnum = Object.entries(enumenum.project_id || {}).map(([key, item]) => ({
      id: key,
      name: item.display,
    })); // 项目名称
    const customerProjEnum = Object.entries(enumenum.customer_proj_id || {}).map(([key, item]) => ({
      id: key,
      proj_name: item.display,
    })); // 客户名称
    const contractEnum = Object.entries(enumenum.contract_id || {}).map(([key, item]) => ({
      id: key,
      name: item.display,
    })); // 合同名称
    // 承运商合同名称
    const carrierEnum = Object.entries(enumenum.carrier_contract_id || {}).map(([key, item]) => ({
      id: key,
      name: item.display,
    })); // 客户名称
    // 承运商名称
    const carrierProjEnum = Object.entries(enumenum.carrier_id || {}).map(([key, item]) => ({
      id: key,
      carrier_name: item.display,
    })); // 合同名称
    console.log(+lineObj.state === 1, lineObj.state);
    this.state = {
      enumenum: {
        // 枚举
        ...enumenum,
        node_type: nodeTypesEnum,
        company_id: companyIdEnum,
        line_type: lineTypeEnum,
        // l_tr_length: lTrLengthEnum,
        // l_tr_type: lTrTypeEnum,
        project: projectEnum,
        customer_proj: customerProjEnum,
        contract: contractEnum,
        carrier_proj: carrierProjEnum, // 承运商名称
        carrier: carrierEnum, // 承运商合同名称
        // 货物表格
      },
      ...lineObj,
      // eslint-disable-next-line react/no-unused-state
      that: prop.that, //  that 信息
      postInfo, // 侧拉顶部表格数据
      goods_list: goods_list || [defGoods], // 货物信息
      isLoading: false,
      card: {},
      switchVal: lineObj.state === undefined ? true : +lineObj.state === 1,
      carrier_id: lineObj.carrier_contract_id,
      carrier_proj_id: lineObj.carrier_id,
      // line_num_system: lineInfo.line_num,
      // line_distance: lineInfo && lineInfo.line_distance ? lineInfo.line_distance : 0, // 车线里程
    };
    // 时间单位处理
    if (+this.hour_rule_value === 1) {
      for (let i = 0; i < this.state.postInfo.length; i++) {
        if (this.state.postInfo[i].node_hour) {
          this.state.postInfo[i].node_hour = (this.state.postInfo[i].node_hour / 60).toFixed(1);
        }
      }
      this.state.line_time = (this.state.line_time / 60).toFixed(1);
      this.state.receipt_hour = (this.state.receipt_hour / 60).toFixed(1);
    }
    // const baseDataSetKey = baseDataSettingKey.project
    // 是否使用了项目数据源
    // this.showProjectBaseData = baseDataSetKey && window.company_setting[baseDataSetKey] && window.company_setting[baseDataSetKey].value
    this.fieldSet = handleFieldSet(); // 获取系统设置的显示必填数据
    this.disabled = prop.parm.opType === 'view';
    // 新增车线应该不显示车线节点
    this.isShowRouterCard = !(prop.parm.opType === 'add');
  }
  setCard = (key, value) => this.setState({ card: { ...this.state.card, [key]: value } });
  setLoading = state => {
    this.setState({
      isLoading: state,
    });
  };
  isEdited = () => this.componentEdited;
  sumTableRow = (postInfo, key = 'node_distance') => {
    let sum = 0;
    postInfo
      .filter(item => item[key])
      .forEach(x => {
        sum += +x[key];
      });
    if (key === 'node_hour') {
      return sum.toFixed(1);
    }
    return parseFloat(sum.toFixed(3));
  };
  // 获取节点类型枚举
  getNodeTypeEnum = line_type => {
    let node_types_enum = this.nodeTypesEnumAll;
    if (line_type) {
      const set_key = lineType2SetKey[line_type] || 'optional_line_node'; // 节点类型要读取的系统设置的key
      const set_value = window.company_setting[set_key]; // 节点类型要读取的系统设置
      node_types_enum = node_types_enum.filter(item => set_value.selc.includes(+item.key));
    }

    return node_types_enum;
  };
  // 是否清空标准线路
  confirmToClearStd = async () => {
    return new Promise(resolve => {
      const dialog = new PopUp(ModalDialog, {
        title: '操作提醒',
        isShow: true,
        autoDestroy: true,
        content: '您正在对车线信息进行变更，保存新车线后，原标准线路信息将清零，是否确定？',
        width: 440,
        bottom: (
          <Flex justify="between">
            <div style={{ flex: 1 }}>
              <Button
                type="primary"
                onClick={async () => {
                  dialog.close();
                  resolve(true);
                }}
              >
                确定
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  dialog.close();
                  resolve(false);
                }}
              >
                取消
              </Button>
            </div>
          </Flex>
        ),
        closable: true,
        maskClosable: true,
        escBind: false,
      }).show();
    });
  };
  /**
   * 判断车线节点是否发生了地址上的变化
   * 节点类型、具体的地址、围栏半径变化才算变化
   * @param {*} originLineNode 原始节点
   * @param {*} newLineNode 变化后的节点
   */
  judgeIfLineNodeChange = (originLineNode, newLineNode) => {
    try {
      if (originLineNode?.length !== newLineNode?.length) {
        return true;
      }
      for (let i = 0; i < newLineNode?.length; i++) {
        if (+originLineNode[i]?.node_type !== +newLineNode[i]?.node_type) {
          return true;
        }
        if (!_.isEqual(originLineNode[i]?.node_address, newLineNode[i]?.node_address)) {
          return true;
        }
        // if (
        //   originLineNode[i]?.fence_shape !== newLineNode[i]?.fence_shape ||
        //   originLineNode[i]?.fence_radius !== newLineNode[i]?.fence_radius
        // ) {
        //   return true;
        // }
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  /**
   * 增加/修改
   * 提交数据
   * pageType
   * operatType
   */
  submitData = async () => {
    if (!(await validateFieldsOf(this.wrap))) {
      return;
    }
    const {
      postInfo,
      line_num,
      line_name,
      line_type,
      company_id,
      use_corp_type,
      project_id,
      customer_proj_id,
      contract_id,
      line_distance,
      line_time,
      receipt_hour,
      remark,
      carrier_id,
      carrier_proj_id,
      // 货物信息
      goods_list,
      // 其他字段
      line_num_system,
      line_nodes_num,
      ext,
    } = this.state;
    const { parm } = this.props;
    const { lineId, opType } = parm;
    const userConfirm = 0; // 0默认 1用户点击了弹框的确认
    const lineDistance = this.sumTableRow(postInfo) || line_distance || ''; // 车线里程
    let lineHour = this.sumTableRow(postInfo, 'node_hour') || line_time || ''; // 车线时效
    let receiptHour = receipt_hour;
    const standardSpeed =
      (+lineDistance > 0 &&
        +lineHour > 0 &&
        (lineDistance / (this.hour_rule_value === 1 ? lineHour : lineHour / 60)).toFixed(2)) ||
      ''; // 标准时速
    const post_info = [...postInfo];
    const nodesNameDict = this.props.i18n.get('nodes_name', '节点名称');
    if (line_name === '') {
      showInfo(ERROR, '请输入车线名称！');
      return false;
    }
    if (post_info.length < 2) {
      showInfo(ERROR, '必须保存2个节点以上！');
      return false;
    }
    if (!line_num && this.line_num_editable) {
      showInfo(ERROR, '请输入车线编号！');
      return;
    }
    const noAddrNode = [];
    const noNameNode = [];
    // 常发货物要求是否有空值
    let nodeAttachmentHasEmpty = false;
    post_info.forEach((item, index) => {
      if (!item.node_name) {
        noNameNode.push(index + 1);
      }
      if (!item.node_address || !item.node_address.poi) {
        noAddrNode.push(index + 1);
      }
      // 如果节点类型不展示，默认为高德地址
      if (_.get(this, 'fieldSet.show.nodeTypeShow') === false) {
        post_info[index].node_type = '7';
      }

      // 如果联系人有常发货物附件，则表示当前行有附件
      if (!item.node_attachment?.length && !item.contractor_customer_has_goods_claims) {
        nodeAttachmentHasEmpty = true;
      }
    });
    const { nodeAttachmentRequired } = this.fieldSet.required;
    if (nodeAttachmentHasEmpty && nodeAttachmentRequired) {
      showInfo(ERROR, '联系人附件不能为空！');
      return;
    }
    if (noNameNode.length > 0 && this.state.switchVal) {
      showInfo(ERROR, `节点${noNameNode.join('、')}的${nodesNameDict}不能为空！`);
      return;
    }
    if (noAddrNode.length && this.state.switchVal) {
      confirmWithReason({
        title: '操作提醒',
        iconType: WARN,
        tips: '[地址坐标]不能为空！',
        noticeTitle: '',
        reason: `请重新选择节点${noAddrNode.join(
          '、',
        )}的[定位地址]，注意一定要从下拉里选，系统可自动读取到[地址坐标]。更多地址细节请填写到[地址备注]里。`,
        btns: { confirm: 1 },
      });
      return;
    }
    // 时间单位处理
    if (+this.hour_rule_value === 1) {
      post_info.forEach((item, index) => {
        if (post_info[index].node_hour) {
          post_info[index].node_hour = (post_info[index].node_hour * 60).toFixed(1);
        }
      });
      lineHour = (lineHour * 60).toFixed(1);
      receiptHour = (receiptHour * 60).toFixed(1);
    }
    const lineInfo = {};
    // 车线表格
    lineInfo.head_id = post_info[0].company_id || -1;
    lineInfo.tail_id = post_info[post_info.length - 1].company_id || -1;
    lineInfo.line_nodes = post_info;
    // 车线字段
    lineInfo.line_num = line_num; // 车线编号
    lineInfo.line_name = line_name; // 车线名称
    lineInfo.line_nodes_num = line_nodes_num;
    lineInfo.line_type = line_type; // 车线类型
    lineInfo.company_id = company_id; // 所属组织
    lineInfo.use_corp_type = use_corp_type; // 使用组织
    lineInfo.customer_proj_id = customer_proj_id; // 合同id 客户名称
    lineInfo.project_id = project_id; // 项目id 项目名称
    lineInfo.contract_id = contract_id; // 项目id 项目名称
    lineInfo.line_distance = lineDistance; // 车线里程
    lineInfo.line_time = lineHour; // 车线时效
    lineInfo.standard_speed = standardSpeed; // 标准时速
    lineInfo.receipt_hour = receiptHour; // 回单时效
    lineInfo.remark = remark; // 备注信息
    // 货物信息表格
    lineInfo.goods_list = goods_list;
    // 其他字段
    lineInfo.line_id = lineId;
    lineInfo.line_num_system = line_num_system;
    lineInfo.line_name_edited = this.line_name_edited || 0;
    lineInfo.line_distance_edited = this.line_distance_edited || 0;
    lineInfo.state = !this.state.switchVal ? 2 : 1; // 2 表示关闭车线节点必填项校验、1表示关闭
    lineInfo.carrier_contract_id = carrier_id; // 承运商合同名称
    lineInfo.carrier_id = carrier_proj_id; // 承运商名称
    // lineInfo.l_dr_id = this.state.l_dr_id
    // lineInfo.l_dr_co_id = this.state.l_dr_co_id
    // lineInfo.l_tr_type = this.state.l_tr_type
    // lineInfo.l_tr_length = this.state.l_tr_length
    // lineInfo.l_tr_team = this.state.l_tr_team
    // lineInfo.l_carrier_id = this.state.l_carrier_id
    // lineInfo.l_truck_id = this.state.l_truck_id
    // lineInfo.l_driver_id = this.state.l_driver_id
    const postData = {
      req: {
        op_type: opType,
        user_confirm: userConfirm,
        line_info: lineInfo,
      },
    };
    const url = '/Basic/Line/lineOp';
    const conf = { method: 'POST', body: postData };
    // 存在标准线路，且车线节点部分属性有修改，保存会清空标准线路
    if (ext?.std_route_state && this.judgeIfLineNodeChange(this.oldLineNodes, postInfo)) {
      this.confirmToClearStd().then(async res => {
        if (res) {
          this.setLoading(true);
          const result = await fetch(url, conf);
          this.setLoading(false);
          this.handleSubmitResult(postData, result);
        }
      });
    } else {
      this.setLoading(true);
      const res = await fetch(url, conf);
      this.setLoading(false);
      this.handleSubmitResult(postData, res);
    }
  };
  handleSubmitResult = (postData, res) => {
    // actions.companyServer.lineOp(postData).then(res => {
    // if (res.type === 'API_SUCCESS' && res.data.errno === 0) {
    const { submitCb } = this.props;
    if (+res.errno === 0) {
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: CHECK,
        isShow: true,
        content: res.errmsg,
        autoDestroy: true,
      }).show();
      this.handleHide();
      console.log(postData, 'postDatapostData');
      submitCb &&
        submitCb({
          ...postData.req.line_info,
          line_id: res.res.line_id,
        });
      this.props.that && setTimeout(this.props.that.handleRefreshData, 2000);
    } else if (+res.errno === -7001) {
      // 新增车线时，g7接口调失败了  需要提示并关闭侧拉
      res.errmsg && showInfo(ERROR, res.errmsg);
      this.handleHide();
    } else {
      showInfo(ERROR, res.errmsg);
      return false;
    }
    // })
  };
  handleShow = () => {
    this.slideAM.handleShow();
  };
  handleHide = () => {
    this.slideAM.handleHide();
  };
  lineNodeAdd = index => {
    if (this.disabled) {
      return;
    }
    this.componentEdited = true;
    const { postInfo } = this.state; // lala
    const item = {
      node_name: '',
      node_type: 7,
    };
    if (index === -1) {
      postInfo.push(item);
    } else {
      postInfo.splice(index + 1, 0, item);
    }
    // postInfo.splice(postInfo.length - 1, 0, item)
    this.setState({
      postInfo: Object.assign([], postInfo),
      line_name: this.setLineName(),
      line_nodes_num: Math.max(postInfo.length - 1, 0),
    });
    this.hasLineNodeAddressChange = true;
  };
  lineNodeMinus = index => {
    if (this.disabled) {
      return;
    }
    this.componentEdited = true;
    const { postInfo } = this.state; // lala
    postInfo.splice(index, 1);
    this.setState({
      postInfo: Object.assign([], postInfo),
      line_name: this.setLineName(),
      line_nodes_num: Math.max(postInfo.length - 1, 0),
    });
    this.hasLineNodeAddressChange = true;
    this.calcDistance(postInfo);
  };
  lineNodeDrage = result => {
    if (this.disabled) {
      return;
    }
    const src = result.source.index;
    const dest = result.destination.index;
    if (src === dest) {
      return;
    }
    this.componentEdited = true;
    const { postInfo } = this.state; // lala
    const tmp = postInfo[src];
    postInfo.splice(src, 1);
    postInfo.splice(dest, 0, tmp);
    this.setState({
      postInfo: Object.assign([], postInfo),
      line_name: this.setLineName(),
      line_nodes_num: Math.max(postInfo.length - 1, 0),
    });
    this.hasLineNodeAddressChange = true;
    this.calcDistance(postInfo);
  };
  isNode = (item, index) => {
    if (item.node_address && item.node_address.poi) {
      return { poi: item.node_address.poi, index };
    }
    return false;
  };
  calcDistance = (nodes, force) => {
    console.log('calcDistance =', force, this.line_distance_edited);
    // if (this.line_distance_edited && this.state.line_distance && !force) {
    // if (this.line_distance_edited && !force) {
    // return
    // }
    const { postInfo } = this.state;
    const _self = this;
    /*
    const path = nodes.filter(item => item.node_address && item.node_address.poi).map(item => item.node_address.poi)
    console.log('path =', path)
    if (path.length > 1) {
      window.calDistance(path).then(({ distance, duration }) => {
        console.log('calDistance =', path, distance, duration)
        this.setState({
          line_distance: Math.round(distance / 1000),
          // line_time: (duration / 3600).toFixed(1)
        })
      })
    }
    */
    const path = nodes.map((item, index) => _self.isNode(item, index)).filter(item => item && item.poi);
    console.log('path =', path);
    if (path.length > 1) {
      path.forEach((x, index) => {
        const nextItem = path[index + 1];
        if (nextItem) {
          const curPath = [x.poi, nextItem.poi];
          window.calDistance(curPath).then(({ distance, duration }) => {
            console.log('calcDistance duration', duration);
            const _line = Math.round(distance / 1000);
            postInfo[x.index].node_distance = _line;
            this.setState({
              postInfo: [...postInfo],
            });
          });
        } else {
          postInfo[x.index].node_distance = 0;
          this.setState({
            postInfo: [...postInfo],
          });
        }
      });
    }
  };
  lineNodeChange = (index, key, val) => {
    const value = val && val.target ? val.target.value : val;
    this.componentEdited = true;
    const { postInfo } = this.state; // lala
    if (key === 'node_name') {
      if (+postInfo[index].node_type === 6 || !postInfo[index].node_type) {
        const address = value.address || {};
        postInfo[index].company_id = value.key;
        postInfo[index].node_name = value.short_name;
        postInfo[index].node_address = address;
        postInfo[index].addr_remark = value.address_remark;
        postInfo[index].contract_phone = value.query_phone;
        postInfo[index].contractor = value.head_name;
        postInfo[index].fence = value.fence;
        postInfo[index].fence_shape = value.fence_shape;
        postInfo[index].fence_radius = value.fence_radius;
        postInfo[index].fence_extra = value.fence_extra;
        // 清空联系人附件，收发货人id
        postInfo[index].contractor_customer_has_goods_claims = false;
        postInfo[index].node_attachment = [];
        postInfo[index].contractor_customer_id = '';
      } else if (+postInfo[index].node_type === 7 || +postInfo[index].node_type === 8) {
        postInfo[index].node_name = value;
      } else {
        postInfo[index].company_id = value.company_id;
        postInfo[index].node_name = value.name;
        postInfo[index].c_id = value.id;
        postInfo[index].node_address = value.address;
        postInfo[index].contract_phone = value.telephone;
        postInfo[index].addr_remark = value.address_remark;
        postInfo[index].contractor = value.name;
        // 清空联系人附件，收发货人id
        postInfo[index].contractor_customer_has_goods_claims = false;
        postInfo[index].node_attachment = [];
        postInfo[index].contractor_customer_id = '';
      }
    } else if (key === 'node_type') {
      postInfo[index] = {
        [key]: value,
      };
      // } else if (key === 'arrival_time' || key === 'departure_time') {
      //   const aVal = value && value.find(item => item)
      //   if (aVal && (!value[0] || !value[1])) {
      //     value = [aVal, aVal]
      //   }
      //   postInfo[index][key] = value
    } else {
      postInfo[index][key] = value;
    }
    const changeTagConfig = SET_CHANGE_TAG[key];
    if (changeTagConfig) {
      const { editedKey, validateKey = '' } = changeTagConfig || {};
      postInfo[index][`${editedKey}`] = !!(typeof value === 'object' ? value?.[validateKey] : value);
    }
    if (key === 'node_address' || key === 'addr_remark') {
      postInfo[index].node_address_edited = true;
    }
    // 标识是否会影响标准线路
    if (key === 'node_address' || key === 'node_type') {
      postInfo[index].node_address_lnglat_edited = true;
    }
    // 修改电子围栏坐标
    if (key === 'node_address' && postInfo[index].fence) {
      postInfo[index].fence[0].latlngs = value.poi;
      postInfo[index].fence[0].center_point = value.poi;
    }

    this.setState({
      postInfo: [...postInfo],
      line_name: this.setLineName(),
    });
    if (key === 'node_name' || key === 'node_address') this.calcDistance(postInfo);
    // if (key === 'node_distance') this.calcLineDistance(postInfo)
  };

  // 联系人失去焦点后，数据处理
  onContractorBlur = (index, value, nodeType) => {
    const { postInfo } = this.state;
    // 如果是数组，标识是选择的
    if (_.isArray(value)) {
      // 标识是选择的，包括清空输入框
      const {
        id = '',
        name = '',
        goods_claims = [],
        telephone,
        address_remark,
        customer_cname,
        address = {},
      } = value[0] || {};
      postInfo[index] = {
        ...postInfo[index],
        contractor: name,
        contractor_customer_id: id,
        contract_phone: telephone,
        contractor_org: customer_cname,
        contractor_customer_has_goods_claims: !!goods_claims.length, // 有常发货物要求，就禁用上传，没有则放开
      };
      // 如果已编辑则不覆盖
      const addressEditedKey = TAG_ENUM.address.editedKey;
      const addressRemarkEditedKey = TAG_ENUM.addrRemark.editedKey;
      // 地址是否被编辑过
      const isSetAddrChangeTag = postInfo[index]?.[addressEditedKey];
      // 地址备注是否被编辑过
      const isSetAddrRemarkChangeTag = postInfo[index]?.[addressRemarkEditedKey];
      if (+nodeType !== 6 && !isSetAddrChangeTag) {
        postInfo[index].node_address = address;
      }
      if (!isSetAddrRemarkChangeTag) {
        postInfo[index].addr_remark = address_remark;
      }
    } else {
      // 如果是一个对象，标识是手动输入的
      postInfo[index].contractor = value.name;
      postInfo[index].contractor_customer_id = '';
      postInfo[index].contractor_customer_has_goods_claims = false;
    }
    /**
     * 1. 联系人有常发货物附件，附件情况，则接口不需要传附件了；
     * 2. 没有常发货物附件，附件清空，自己传；
     * 3. 手动输入联系人，附件清空，附件可以自定义上传，不处理“联系电话”、“详细地址”、“地址备注”、“联系人单位”
     */
    postInfo[index].node_attachment = [];

    this.setState({
      postInfo: [...postInfo],
    });
  };

  lineNodeBlur = (index, key, val) => {
    const value = val && val.target ? val.target.value : val;
    if (!value) return false;
    const { postInfo } = this.state;
    const regu = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
    if (!regu.test(value)) {
      showInfo(ERROR, '只能填写正数！');
      postInfo[index][key] = '';
    }
    const decimal = value.split('.')[1];
    if (decimal && decimal.length > 3) {
      showInfo(ERROR, '最多填写3位小数！');
      postInfo[index][key] = '';
    }
    this.setState({
      postInfo: [...postInfo],
    });
  };

  // 围栏半径 和 外延长度点击事件
  fenceValueClick = (row, key, index) => {
    // 查看侧拉中不可点击
    if (this.disabled) return null;
    // 有值时不可点击
    if (row[key]) return null;
    this.fenceIconClick(row, index);
  };

  // 围栏半径 和 外延长度 图标点击事件
  fenceIconClick = (row, index) => {
    const { node_type, node_address = {} } = row;
    const { poi } = node_address;
    const nodesAddrDict = this.props.i18n.get('nodes_addr', '节点地址');
    if (!poi) {
      showInfo(ERROR, `请先输入${nodesAddrDict}!`);
      return;
    }
    const { fence = [] } = row;
    const params = {
      className: 'line-fence',
      data: fence || [],
      markerDraggable: +node_type !== 6,
      markerPoi: poi,
      submitCallback: submitParams => this.fenceCallback(submitParams, row, index),
    };
    const dialog = new PopUp(Fence, params);
    dialog.show();
  };
  // 设置电子围栏 侧拉 确定回调
  fenceCallback = (params, row, index) => {
    const { node_type, node_address = {} } = row;
    const { poi: node_poi } = node_address;
    const { poi, address, fence } = params;
    if (node_type !== 6 && node_poi !== poi) {
      this.lineNodeChange(index, 'node_address', address); // 更新地址
    }

    this.lineNodeChange(index, 'fence', fence); // 更新围栏信息
    const fenceObj = formatFenceData(fence);
    this.lineNodeChange(index, 'fence_shape', fenceObj.shape); // 围栏形状
    this.lineNodeChange(index, 'fence_radius', fenceObj.radius || ''); // 表格 围栏半径
    this.lineNodeChange(index, 'fence_extra', fenceObj.extra || ''); // 表格 外延长度
  };

  // 选择常发货物后回调
  onFileChange = (files, index) => {
    const { postInfo } = this.state;
    const newData = [...postInfo];
    newData[index].node_attachment = files;
    this.setState({
      postInfo: newData,
    });
    dataAnalyticTrack(['常发货物要求', '车线管理', '上传联系人附件']);
  };

  // 节点信息
  renderTable = () => {
    const _html = [];
    let { postInfo = [] } = this.state;
    const { enumenum = {}, speClass, switchVal } = this.state;

    const { node_type: node_type_enum } = enumenum;
    const node_distance_type_enum = (enumenum?.node_distance_type || [])?.map(el => {
      return {
        ...el,
        name: el.display,
      };
    });
    const channelHtm = [];
    if (postInfo.length < 1) {
      postInfo = [{}, {}];
    }
    const { show, required, className } = this.fieldSet;
    const {
      nodeTypeShow,
      nodeNameShow,
      nodeAddressShow,
      addrRemarkShow,
      contractorShow,
      contractPhoneShow,
      nodeDistanceShow,
      // 里程类型
      nodeDistanceTypeShow,
      nodeHourShow,
      effectRadiusShow,
      fenceRadiusShow,
      fenceExtraShow,
      nodeRemarkShow,
      nodeAttachmentShow,
      contractorOrgShow,
    } = show;
    const {
      nodeTypeRequired,
      nodeNameRequired,
      nodeAddressRequired,
      addrRemarkRequired,
      contractorRequired,
      contractPhoneRequired,
      nodeDistanceRequired,
      nodeDistanceTypeRequired,
      nodeHourRequired,
      effectRadiusRequired,
      nodeRemarkRequired,
      contractorOrgRequired,
    } = required;
    let { nodeTypeClass, nodeNameClass, nodeAddressClass } = className;
    const {
      addrRemarkClass,
      contractorClass,
      contractPhoneClass,
      nodeDistanceClass,
      nodeDistanceTypeClass,
      nodeHourClass,
      effectRadiusClass,
      fenceRadiusClass,
      fenceExtraClass,
      nodeRemarkClass,
      contractorOrgClass,
      nodeAttachmentClass,
    } = className;
    const field = [
      'node_type',
      'node_name',
      'node_address',
      'addr_remark',
      'contractor',
      'contract_phone',
      'contractor_org', // 联系人单位
      'node_attachment', // 联系人附件
      'node_distance',
      'node_distance_type',
      'node_hour',
      'effect_radius',
      'fence_radius',
      'fence_extra',
      'node_remark',
    ];
    const fieldShow = field.some(key => show[formatCamelCase(`${key}_show`)]);
    const nodesNameDict = this.props.i18n.get('nodes_name', '节点名称');
    const nodesAddrDict = this.props.i18n.get('nodes_addr', '节点地址');
    if (!switchVal) {
      nodeTypeClass = 'node_type';
      nodeNameClass = 'node_name';
      nodeAddressClass = 'node_address';
    }
    if (!fieldShow) return null;
    postInfo.map((key, index) => {
      // let _title = '途经'
      let _data = formatObjectToArray(enumenum.tail_id);
      const _midHtml = dragHandleProps => (
        <td className="fn-icon-op">
          <img
            {...dragHandleProps}
            className="icon-drag"
            alt="drag"
            src="https://static.g7cdn.com/fe-cdn/icon-drag_16.png"
          />
          <i className="fn-icon fn-icon-minus-rad" onClick={() => this.lineNodeMinus(index)} />
          <div className="node-add">
            <span {...(!this.disabled ? addNodeTip : {})} onClick={() => this.lineNodeAdd(index)}>
              +
            </span>
          </div>
        </td>
      );
      const _endHtml = dragHandleProps => (
        <td className="fn-icon-op">
          <img
            {...dragHandleProps}
            className="icon-drag"
            alt="drag"
            src="https://static.g7cdn.com/fe-cdn/icon-drag_16.png"
          />
          <i className="fn-icon fn-icon-minus-rad" style={{ color: '#d2d3d4' }} />
          <div className="node-add">
            <span {...(!this.disabled ? addNodeTip : {})} onClick={() => this.lineNodeAdd(index)}>
              +
            </span>
          </div>
        </td>
      );
      let _iconHtml = dragHandleProps => _midHtml(dragHandleProps);
      if (index === 0) {
        // _title = '出发'
        _data = formatObjectToArray(enumenum.head_id);
      }
      if (postInfo.length <= 2) {
        // _title = '到达'
        _iconHtml = dragHandleProps => _endHtml(dragHandleProps);
      }
      const addrDisable =
        +key.node_type === 6 || ([1, 2].includes(+key.node_type) && key.node_address && key.node_address.poi);
      const addrShowVal = key.node_address && key.node_address.show_val;
      const isView = this.disabled;
      const isLastOne = postInfo.length === index + 1;
      const nodeDistanceDisabled = isView || isLastOne;
      const nodeHourDisabled = isView || isLastOne;
      const nodeDistanceTypeDisable = isView || isLastOne;
      const nodeDistancePlace = nodeDistanceDisabled ? '' : '请输入里程';
      const nodeDistanceTypePlace = nodeDistanceDisabled ? '' : '请选择里程类型';
      const adressPlace = isView ? '' : '请输入地址备注';
      const nodeHourPlace = nodeHourDisabled ? '' : '请输入时效';
      const peoplePlace = isView ? '' : '请输入联系人';
      const phonePlace = isView ? '' : '请输入联系人电话';
      const fenceRadius =
        key.fence_radius && Number.isFinite(+key.fence_radius) && +key.fence_radius ? key.fence_radius : '请设置';
      const fenceExtra =
        key.fence_extra && Number.isFinite(+key.fence_extra) && +key.fence_extra ? key.fence_extra : '请设置';
      channelHtm.push(
        <Draggable draggableId={`index-${index}`} key={`index-${index}`} index={index} isDragDisabled={this.disabled}>
          {(provided, snapshot) => (
            <tr ref={provided.innerRef} {...provided.draggableProps}>
              {_iconHtml(provided.dragHandleProps)}
              <td>{index + 1}</td>
              {nodeTypeShow && (
                <td>
                  <Select
                    required={nodeTypeRequired}
                    className="node-type"
                    data={node_type_enum}
                    value={key.node_type}
                    onChange={value => this.lineNodeChange(index, 'node_type', value)}
                    disabled={this.disabled}
                  />
                </td>
              )}
              {nodeNameShow && (
                <td>
                  {/* {
                  +key.node_type === 1 && <SelectDrop
                    fetchApi={corSugApi}
                    tableHeader={corHeader}
                    defaultSelected={[{ name: key.node_name, id: key.c_id }]}
                    handleSelected={(value) => this.lineNodeChange(index, 'node_name', value)}
                    showKey="name"
                    uniqueKey="id"
                    disabled={this.disabled}
                  />
                }
                {
                  +key.node_type === 2 && <SelectDrop
                    fetchApi={ceeSugApi}
                    tableHeader={ceeHeader}
                    defaultSelected={[{ name: key.node_name, id: key.c_id }]}
                    handleSelected={(value) => this.lineNodeChange(index, 'node_name', value)}
                    showKey="name"
                    uniqueKey="id"
                    disabled={this.disabled}
                  />
                } */}
                  {/* ==================== 组织 ==================== */}
                  {+key.node_type === 6 && (
                    <Tips position="top" title={key.node_name}>
                      <OrgSug
                        required={nodeNameRequired}
                        menuListStyle={{ width: '200px' }}
                        isMultiple={false}
                        selectFirstLine
                        data={_data}
                        scope="group__3,4,5,6,7,8,9,10"
                        showKey="short_name"
                        defaultSelected={[{ short_name: key.node_name, key: key.company_id }]}
                        uniqueKey="key"
                        handleSelected={value => this.lineNodeChange(index, 'node_name', value)}
                        tableHeader={orgHeader}
                        key={`lineNode${index}`}
                        shouldFilter
                        fetchApi={orgFetchApi}
                        orgFields={['address']}
                        mode="list"
                        showHeader
                        disabled={this.disabled}
                      />
                    </Tips>
                  )}
                  {/* ==================== 高德地址 & 四级地址 ==================== */}
                  {(+key.node_type === 7 || +key.node_type === 8) && (
                    <Tips position="top" title={key.node_name}>
                      <Input
                        required={this.state.switchVal === false ? false : nodeNameRequired}
                        value={key.node_name}
                        onChange={value => this.lineNodeChange(index, 'node_name', value)}
                        disabled={this.disabled}
                      />
                    </Tips>
                  )}
                </td>
              )}
              {nodeAddressShow && (
                <td className="node-address">
                  {addrDisable && (
                    <Tips className="node_address_tips" position="top" title={addrShowVal}>
                      {addrShowVal}
                    </Tips>
                  )}
                  {!addrDisable && (
                    <Tips position="top" title={addrShowVal}>
                      <Address
                        g7Map={this.props?.g7Map}
                        required={this.state.switchVal === false ? false : nodeAddressRequired}
                        needSelect
                        mode={+key.node_type === 8 ? 'district' : 'address'}
                        disabled={this.disabled || addrDisable}
                        mapIcon={false}
                        fullName
                        value={key.node_address}
                        onChange={val => this.lineNodeChange(index, 'node_address', val)}
                        onSelect={val => this.lineNodeChange(index, 'node_address', val)}
                      />
                    </Tips>
                  )}
                </td>
              )}
              {addrRemarkShow && (
                <td className="node-remark">
                  <Tips position="top" title={key.addr_remark}>
                    <Input
                      required={addrRemarkRequired}
                      value={key.addr_remark}
                      placeholder={adressPlace}
                      onChange={value => this.lineNodeChange(index, 'addr_remark', value)}
                      disabled={this.disabled}
                    />
                  </Tips>
                </td>
              )}
              {contractorShow && (
                <td>
                  <SelectDrop
                    required={contractorRequired}
                    className="contractor"
                    value={key.contractor}
                    defaultSelected={key.contractor}
                    showHeader
                    showKey="name"
                    uniqueKey="name"
                    tableHeader={ceeAndCorHeader}
                    fetchApi={ceeAndCorSug}
                    isMultiple={false}
                    selectOnly={false}
                    onBlur={value => this.onContractorBlur(index, value, key.node_type)}
                    disabled={this.disabled}
                  />
                </td>
              )}
              {contractPhoneShow && (
                <td>
                  <Tips position="top" title={key.contract_phone}>
                    <Input
                      required={contractPhoneRequired}
                      value={key.contract_phone}
                      placeholder={phonePlace}
                      onChange={value => this.lineNodeChange(index, 'contract_phone', value)}
                      disabled={this.disabled}
                    />
                  </Tips>
                </td>
              )}
              {/* 联系人单位 */}
              {contractorOrgShow && (
                <td>
                  <Tips position="top" title={key.contractor_org}>
                    <Input
                      required={contractorOrgRequired}
                      value={key.contractor_org}
                      placeholder="请输入联系人单位"
                      maxLength="50"
                      onChange={value => this.lineNodeChange(index, 'contractor_org', value)}
                      disabled={this.disabled}
                    />
                  </Tips>
                </td>
              )}
              {/* 联系人附件 */}
              {nodeAttachmentShow && (
                <td>
                  {/* 如果自动带出了常发货物附件，则提示 */}
                  {key.contractor_customer_has_goods_claims ? (
                    <Icon iconType="icon-upload1" tips="联系人已维护常发货物附件，建单时附件从联系人带入" />
                  ) : (
                    <UploadFile
                      className="node-attachment"
                      type="customer_goods"
                      showType="link"
                      fileList={key.node_attachment}
                      disabled={this.disabled}
                      onChange={fileList => this.onFileChange(fileList, index)}
                      isOss
                      displayName="hover"
                      maxNum={10}
                      sizeLimit={10}
                    />
                  )}
                </td>
              )}
              {nodeDistanceShow && (
                <td className="node-remark">
                  <Input
                    required={nodeDistanceRequired}
                    value={isLastOne ? '' : key.node_distance || 0}
                    placeholder={nodeDistancePlace}
                    onChange={value => this.lineNodeChange(index, 'node_distance', value)}
                    onBlur={value => this.lineNodeBlur(index, 'node_distance', value)}
                    disabled={nodeDistanceDisabled}
                  />
                </td>
              )}
              {nodeDistanceTypeShow && (
                <td>
                  <Select
                    required={nodeDistanceTypeRequired && !isLastOne}
                    data={node_distance_type_enum}
                    value={isLastOne ? '' : key.node_distance_type || 0}
                    placeholder={nodeDistanceTypePlace}
                    onChange={value => this.lineNodeChange(index, 'node_distance_type', value)}
                    disabled={nodeDistanceTypeDisable}
                  />
                </td>
              )}
              {nodeHourShow && (
                <td>
                  <Input
                    required={nodeHourRequired}
                    value={isLastOne ? '' : key.node_hour || 0}
                    placeholder={nodeHourPlace}
                    onChange={value => this.lineNodeChange(index, 'node_hour', value)}
                    disabled={nodeHourDisabled}
                    pattern={regulars.FLOAT_P_1}
                  />
                </td>
              )}
              {/* <td>
                <TimePeroidPicker
                  value={key.arrival_time}
                  clearMode="both"
                  onChange={(value) => this.lineNodeChange(index, 'arrival_time', value)}
                  disabled={this.disabled}
                />
              </td>
              <td>
                <TimePeroidPicker
                  value={key.departure_time}
                  onChange={(value) => this.lineNodeChange(index, 'departure_time', value)}
                  disabled={this.disabled}
                />
              </td> */}
              {effectRadiusShow && (
                <td key="effect_radius">
                  <Input
                    required={effectRadiusRequired}
                    pattern={FLOAT_P_1}
                    value={key.effect_radius}
                    onChange={value => this.lineNodeChange(index, 'effect_radius', value)}
                    disabled={this.disabled}
                  />
                </td>
              )}
              {/* ================ 围栏半径 ================ */}
              {fenceRadiusShow && (
                <td key="fence_radius" className="fence_radius">
                  {key.fence_shape !== 'polygon' && (
                    <span
                      className={`fn-value${fenceRadius === '请设置' ? ' no-value' : ''}`}
                      onClick={() => this.fenceValueClick(key, 'fence_radius', index)}
                    >
                      {fenceRadius}
                    </span>
                  )}
                  {!this.disabled && (
                    <Icon
                      iconType="icon-weilan"
                      tipsCls="fence-help"
                      tips="点击设置围栏"
                      onClick={() => this.fenceIconClick(key, index)}
                    />
                  )}
                </td>
              )}
              {/* ================ 外延长度 ================ */}
              {/* {fenceExtraShow && (
                <td key="fence_extra" className="fence_extra">
                  <span
                    className={`fn-value${fenceExtra === '请设置' ? ' no-value' : ''}`}
                    onClick={() => this.fenceValueClick(key, 'fence_extra', index)}
                  >
                    {fenceExtra}
                  </span>
                  {!this.disabled && (
                    <Icon
                      iconType="icon-weilan"
                      tipsCls="fence-help"
                      tips="点击设置围栏"
                      onClick={() => this.fenceIconClick(key, index)}
                    />
                  )}
                </td>
              )} */}
              {/* ================ 备注 ================ */}
              {nodeRemarkShow && (
                <td key="node_remark">
                  <Input
                    required={nodeRemarkRequired}
                    value={key.node_remark}
                    onChange={value => this.lineNodeChange(index, 'node_remark', value)}
                    disabled={this.disabled}
                  />
                </td>
              )}
            </tr>
          )}
        </Draggable>,
      );
      return channelHtm;
    });
    _html.push(
      <DragDropContext onDragEnd={this.lineNodeDrage}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {
                <table ref={r => (this.routeTable = r)} className="fn-table-a fn-table_w100" style={{ height: 'auto' }}>
                  <thead>
                    <tr>
                      <th className="w-4">
                        <i
                          className="fn-icon fn-icon-add-rad"
                          onClick={() => {
                            this.lineNodeAdd(-1);
                          }}
                        />
                      </th>
                      <th className="w-4">序号</th>
                      {nodeTypeShow && (
                        <th className={nodeTypeClass} width="80">
                          节点类型
                        </th>
                      )}
                      {nodeNameShow && <th className={nodeNameClass}>{nodesNameDict}</th>}
                      {nodeAddressShow && (
                        <th className={nodeAddressClass} width="120">
                          {nodesAddrDict}
                        </th>
                      )}
                      {addrRemarkShow && <th className={addrRemarkClass}>地址备注</th>}
                      {contractorShow && <th className={contractorClass}>联系人</th>}
                      {contractPhoneShow && <th className={contractPhoneClass}>联系电话</th>}
                      {contractorOrgShow && <th className={contractorOrgClass}>联系人单位</th>}
                      {nodeAttachmentShow && <th className={nodeAttachmentClass}>联系人附件</th>}
                      {nodeDistanceShow && <th className={nodeDistanceClass}>里程(km)</th>}
                      {nodeDistanceTypeShow && <th className={nodeDistanceClass}>里程类型</th>}
                      {nodeHourShow && (
                        <th className={nodeHourClass}>时效({+this.hour_rule_value === 1 ? 'h' : 'min'})</th>
                      )}
                      {/* <th className="w-11">到达时间<Icon iconType="icon-help" tips="仅对按派车计划自动生成派车单时有效。" /></th>
                  <th className="w-11">离开时间<Icon iconType="icon-help" tips="仅对按派车计划自动生成派车单时有效。" /></th> */}
                      {effectRadiusShow && (
                        <th className={effectRadiusClass}>
                          调度半径(km)
                          <Icon iconType="icon-help" tips={effect_radius_tip} />
                        </th>
                      )}
                      {fenceRadiusShow && <th className={fenceRadiusClass}>围栏半径(m)</th>}
                      {/* {fenceExtraShow && <th className={fenceExtraClass}>外延长度(m)</th>} */}
                      {nodeRemarkShow && <th className={nodeRemarkClass}>备注</th>}
                    </tr>
                  </thead>
                  <tbody>{channelHtm}</tbody>
                </table>
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>,
    );
    return _html;
  };
  onNameChange = (ele, key) => {
    this.componentEdited = true;
    this[`${key}_edited`] = 1;
    this.setState({
      [key]: ele.target.value,
    });
  };
  // 项目名称的sug
  // sugProject = (keyword, { fetch: fetchProject }) => {
  //   const isConfigProjectBaseData = _.get(window.company_setting, 'proj_data_source.value')
  //   let sug
  //   if (isConfigProjectBaseData) {
  //     sug = baseDataSug({ fetch: fetchProject, keyword, key: isConfigProjectBaseData, searchKey: 'proj_name' })
  //   } else {
  //     sug = projectSug({ fetch: fetchProject, keyword })
  //   }
  //   return sug.then(data => this.setEnums('project', data))
  // }
  // sug函数
  sugFunc = async (key, keyword) => {
    let params = {};
    // 基础数据源 设置项的值
    // const baseDataSetKey = baseDataSettingKey[key]
    // if (baseDataSetKey && window.company_setting[baseDataSetKey] && window.company_setting[baseDataSetKey].value) {
    //   const baseDataId = window.company_setting[baseDataSetKey].value
    //   let obj = { fetch, keyword, key: baseDataId }
    //   if (key === 'project') obj.searchKey = 'proj_name'
    //   const res = baseDataSug({ ...obj })
    //   res.then(data => this.setEnums(key, data))
    // } else {
    const { company_id, project_id, customer_proj_id, contract_id } = this.state;
    // 非基础数据源
    const query = {};
    switch (key) {
      case 'company_id': // 所属组织
        params = {
          type: 'sub',
          search: keyword,
          need_list: 1,
          page_num: 200,
        };
        break;
      case 'contract': // 客户合同名称
        if (project_id && project_id.length) query.project_id = project_id;
        if (customer_proj_id) query.customer_proj_id = customer_proj_id;
        query.type = 'customer';
        params = {
          company_id,
          search: keyword,
          query,
        };
        break;
      case 'customer_proj': // 客户名称
        // if (project_id && project_id.length) query.project_id = project_id
        // if (contract_id) query.contract_id = contract_id
        params = {
          company_id,
          search: keyword,
          is_multi: 1, // 1表示需要带出合同
        };
        break;
      case 'carrier_proj': // 承运商名称
        // if (project_id && project_id.length) query.project_id = project_id
        // if (customer_proj_id) query.customer_proj_id = customer_proj_id
        params = {
          company_id,
          search: keyword,
          cache_key: 'dispatch_carrier_sug_history2_',
          query: [
            {
              _logic: 'not',
              slave_pid: +window.company_id,
            },
          ],
          is_multi: 1, // 1表示需要带出合同
        };
        break;
      case 'carrier': // 承运商合同名称
        // if (project_id && project_id.length) query.project_id = project_id
        // if (contract_id) query.contract_id = contract_id
        query.type = 'carrier';
        params = {
          company_id,
          search: keyword,
          query,
        };
        break;
      case 'project': // 项目名称
        if (customer_proj_id) query.customer_proj_id = customer_proj_id;
        // if (contract_id) query.contract_id = contract_id
        params = {
          company_id,
          search: keyword,
          query,
        };
        break;
      default:
        params = { search: keyword };
    }
    const conf = { method: 'POST', body: { req: params } };
    const res = await fetch(sugUrl[key], conf);
    if (res.errno !== 0) {
      res.errmsg && showInfo(ERROR, res.errmsg);
      return;
    }
    const data = res.res || [];
    this.setEnums(key, data);
    // }
  };
  lineTypeSelected = value => {
    // const value = ele.id
    // const maps = { 1: 'tr_line_node_types', 3: 'pickup_line_node_types', 4: 'delivery_line_node_types', 6: 'shuttle_line_node_types' }
    // const nodeTypesEnum = this.nodeTypesEnum.filter(t => window.company_setting[maps[ele.id]].selc.includes(+t.key))
    const nodeTypesEnum = this.getNodeTypeEnum(+value);
    const { postInfo, enumenum } = this.state;
    const new_postInfo = postInfo.map(t => {
      const flag = nodeTypesEnum.some(c => +c.key === +t.node_type);
      if (!flag) {
        return {
          node_address: {},
        };
      }
      return t;
    });
    this.setState({
      enumenum: {
        ...enumenum,
        node_type: nodeTypesEnum,
      },
      line_type: value,
      // eslint-disable-next-line react/no-unused-state
      nodeTypesEnum,
      postInfo: new_postInfo,
    });
  };
  onFormInfoChange = key => val => {
    this.setState({
      [key]: val && val.target ? val.target.value : val,
    });
  };
  // 设置枚举
  setEnums = (key, value) => {
    console.log(key, '0000', value);
    this.setState({
      enumenum: {
        ...this.state.enumenum,
        [key]: value,
      },
    });
  };
  setLineName = arr => {
    if (Number(this.line_name_edited) === 1 && this.state.line_name) {
      return this.state.line_name;
    }
    let _lineName = '';
    const _arr = arr || this.state.postInfo;
    _arr.map((key, index) => {
      const _name = key.node_name || '';
      if (index === 0) {
        _lineName += _name;
      } else {
        _lineName += `->${_name}`;
      }
      return _lineName;
    });
    const _lineNameShort = _lineName.replace(/(->)\1+/g, '$1');
    return _lineNameShort;
  };
  formInfoSelected = key => val => {
    console.log(key, val, '333333');
    let value = val;
    const upd = {
      [key]: value,
    };
    if (key === 'project_id') {
      // 项目名称
      value = value.filter(item => item.id);
      // 下拉选择的项目对应多个客户时
      if (value.filter(item => item.customer_proj_id !== value[0].customer_proj_id).length) {
        showInfo(ERROR, '只能选择同一客户的项目！');
        return false;
      }
      if (value && value.length) {
        upd[key] = value.map(item => item.id);
        upd.contract_id = (value && value[0].contract_id) || '';
        upd.customer_proj_id = (value && value[0].customer_proj_id) || '';
        // 枚举处理
        const enmus = {};
        if (value && value[0].contract_id) {
          enmus.contract = [{ id: value[0].contract_id, name: value[0].contract_name }];
        }
        if (value && value[0].customer_proj_id) {
          enmus.customer_proj = [{ id: value[0].customer_proj_id, proj_name: value[0].customer_proj_name }];
        }
        upd.enumenum = {
          ...this.state.enumenum,
          ...enmus,
        };
      } else {
        upd[key] = '';
      }
    } else if (key === 'customer_proj_id') {
      // 客户名称
      if (value) {
        upd[key] = value.id;
        upd.project_id = (value.project_id && [value.project_id]) || [];
        upd.contract_id = value.contract_id || '';
        upd.enumenum = {
          ...this.state.enumenum,
          project: [],
          contract_id: [],
        };
      } else {
        upd[key] = '';
      }
    } else if (key === 'contract_id') {
      // 合同名称
      console.log(value, 'value');
      if (value) {
        upd[key] = value.id;
        upd.project_id = (value.project_id && [value.project_id]) || [];
        upd.customer_proj_id = value.customer_proj_id || '';
        // 枚举处理
        const enmus = {};
        if (value.project_id) {
          enmus.project = [{ id: value.project_id, name: value.project_name }];
        }
        if (value.customer_proj_id) {
          enmus.customer_proj = [{ id: value.customer_proj_id, proj_name: value.customer_proj_name }];
        }
        upd.enumenum = {
          ...this.state.enumenum,
          ...enmus,
        };
      } else {
        upd[key] = '';
      }
    } else if (key === 'carrier_proj_id') {
      // 承运商名称
      if (value) {
        upd[key] = value.id;
        upd.project_id = (value.project_id && [value.project_id]) || [];
        upd.carrier_id = value.carrier_contractr_id || ''; //
        upd.enumenum = {
          ...this.state.enumenum,
          project: [],
          carrier_id: [],
        };
      } else {
        upd[key] = '';
      }
    } else if (key === 'carrier_id') {
      // 承运商合同名称
      console.log('999999999', value);
      if (value) {
        upd[key] = value.id;
        upd.project_id = (value.project_id && [value.project_id]) || [];
        upd.carrier_proj_id = value.carrier_id || ''; // 承运商名称d
        // 枚举处理
        const enmus = {};
        if (value.project_id) {
          enmus.project = [{ id: value.project_id, name: value.project_name }];
        }
        if (value.carrier_id) {
          enmus.carrier_proj = [{ id: value.carrier_id, carrier_name: value.carrier_name }];
        }
        upd.enumenum = {
          ...this.state.enumenum,
          ...enmus,
        };
      } else {
        upd[key] = '';
      }
    }
    this.setState(upd);
  };
  // 线路信息
  renderLine = () => {
    const {
      postInfo,
      line_num,
      line_name,
      line_type,
      customer_proj_id,
      contract_id,
      project_id = [],
      company_id,
      use_corp_type,
      line_time,
      line_distance,
      receipt_hour,
      remark,
      enumenum,
      line_nodes_num,
      carrier_id,
      carrier_proj_id,
    } = this.state;
    const { line_type: lineTypeEnum, project: projectEnum, customer_proj: customerProjEnum } = enumenum;
    const {
      company_id: companyIdEnum,
      use_corp_type: useCorpTypeEnum,
      contract: contractEnum,
      carrier_proj: carrierProjEnum,
      carrier: carrierEnum,
    } = enumenum;
    // 计算车效里程和时效的时候不能计算最后一个节点
    const lineDistance = this.sumTableRow([...postInfo].splice(0, postInfo.length - 1)) || line_distance || ''; // 车线里程
    const lineHour = this.sumTableRow([...postInfo].splice(0, postInfo.length - 1), 'node_hour') || line_time || ''; // 车线时效
    const standardSpeed =
      (+lineDistance > 0 &&
        +lineHour > 0 &&
        (lineDistance / (+this.hour_rule_value === 1 ? lineHour : lineHour / 60)).toFixed(2)) ||
      ''; // 标准时速
    // 显示 && 必填处理
    const { show, required } = this.fieldSet;
    const {
      lineNumShow,
      lineNameShow,
      lineTypeShow,
      companyIdShow,
      useCorpTypeShow,
      customerProjIdShow,
      contractIdShow,
      projectIdShow,
      lineDistanceShow,
      lineNodesNumShow,
      lineTimeShow,
      standardSpeedShow,
      receiptHourShow,
      remarkShow,
      carrierIdShow,
      carrierContractIdShow,
    } = show;
    const {
      lineNumRequired,
      lineNameRequired,
      lineTypeRequired,
      companyIdRequired,
      useCorpTypeRequired,
      customerProjIdRequired,
      contractIdRequired,
      projectIdRequired,
      lineDistanceRequired,
      lineNodesNumRequired,
      lineTimeRequired,
      standardSpeedRequired,
      receiptHourRequired,
      remarkRequired,
      carrierContractIdRequired,
      carrierIdRequired,
    } = required;
    return (
      <div className="info-form">
        {lineNumShow && (
          <div className="info-form-item">
            <Label isRequired={lineNumRequired}>车线编号</Label>
            <Input
              required={lineNumRequired}
              placeholder="车线编号"
              className="line-num"
              value={line_num}
              disabled={this.disabled || !this.line_num_editable}
              onChange={ele => this.onNameChange(ele, 'line_num')}
            />
          </div>
        )}
        {lineNameShow && (
          <div className="info-form-item x2">
            <Label isRequired={lineNameRequired}>车线名称</Label>
            <Input
              required={lineNameRequired}
              placeholder="车线名称"
              className="line-name"
              value={line_name}
              onChange={ele => this.onNameChange(ele, 'line_name')}
              disabled={this.disabled}
            />
          </div>
        )}
        {lineTypeShow && (
          <div className="info-form-item">
            <Label isRequired={lineTypeRequired}>车线类型</Label>
            <Select
              required={lineTypeRequired}
              menuListStyle={{ width: '200px' }}
              autoActiveFirstOption
              data={lineTypeEnum}
              value={line_type}
              onChange={this.lineTypeSelected}
              disabled={this.disabled}
            />
          </div>
        )}
        {companyIdShow && (
          <div className="info-form-item">
            <Label isRequired={companyIdRequired}>所属组织</Label>
            <Select
              required={companyIdRequired}
              data={companyIdEnum}
              value={company_id}
              onChange={this.formInfoSelected('company_id')}
              filter={keyword => this.sugFunc('company_id', keyword)}
              disabled={this.disabled}
            />
          </div>
        )}
        {useCorpTypeShow && (
          <div className="info-form-item">
            <Label isRequired={useCorpTypeRequired}>使用组织</Label>
            <Select
              required={useCorpTypeRequired}
              data={useCorpTypeEnum}
              value={use_corp_type}
              onChange={this.formInfoSelected('use_corp_type')}
              disabled={this.disabled}
            />
          </div>
        )}
        {contractIdShow && (
          <div className="info-form-item">
            <Label isRequired={contractIdRequired}>客户合同名称</Label>
            <Select
              required={contractIdRequired}
              value={contract_id}
              data={contractEnum}
              disabled={this.disabled}
              map={false}
              compare="id"
              header={contractHeader}
              onChange={this.formInfoSelected('contract_id')}
              filter={keyword => this.sugFunc('contract', keyword)}
            />
          </div>
        )}
        {customerProjIdShow && (
          <div className="info-form-item">
            <Label isRequired={customerProjIdRequired}>客户名称</Label>
            <Select
              required={customerProjIdRequired}
              value={customer_proj_id || ''}
              data={customerProjEnum}
              disabled={this.disabled}
              map={false}
              compare="id"
              format="proj_name"
              header={customerProjHeader}
              onChange={this.formInfoSelected('customer_proj_id')}
              filter={keyword => this.sugFunc('customer_proj', keyword)}
            />
          </div>
        )}
        {console.log(carrierEnum, carrier_id, carrierProjEnum, carrier_proj_id)}
        {carrierContractIdShow && (
          <div className="info-form-item">
            <Label isRequired={carrierContractIdRequired}>承运商合同名称</Label>
            <Select
              required={carrierContractIdRequired}
              value={carrier_id}
              data={carrierEnum}
              disabled={this.disabled}
              map={false}
              compare="id"
              format="name"
              header={carrierHeader}
              onChange={this.formInfoSelected('carrier_id')}
              filter={keyword => this.sugFunc('carrier', keyword)}
            />
          </div>
        )}
        {carrierIdShow && (
          <div className="info-form-item">
            <Label isRequired={carrierIdRequired}>承运商名称</Label>
            <Select
              required={carrierIdRequired}
              data={carrierProjEnum}
              value={carrier_proj_id}
              disabled={this.disabled}
              map={false}
              compare="id"
              format="carrier_name"
              header={carrierProjHeader}
              onChange={this.formInfoSelected('carrier_proj_id')}
              filter={keyword => this.sugFunc('carrier_proj', keyword)}
            />
          </div>
        )}
        {projectIdShow && (
          <div className="info-form-item">
            <Label isRequired={projectIdRequired}>项目名称</Label>
            {/* {this.showProjectBaseData ? <Select
            multiple
            required={projectIdRequired}
            value={project_id}
            data={projectEnum}
            disabled={this.disabled}
            map={false}
            compare="id"
            format="proj_name"
            header={['proj_name']}
            // header={projectHeader}
            onChange={this.formInfoSelected('project_id')}
            filter={(keyword) => this.sugFunc('project', keyword)}
          /> */}
            <Select
              multiple
              required={projectIdRequired}
              value={project_id}
              data={projectEnum}
              disabled={this.disabled}
              map={false}
              compare="id"
              header={projectHeader}
              onChange={this.formInfoSelected('project_id')}
              filter={keyword => this.sugFunc('project', keyword)}
            />
          </div>
        )}
        {lineNodesNumShow && (
          <div className="info-form-item line-nodes-num">
            <Label isRequired={lineNodesNumRequired}>点位数</Label>
            <Input
              required={lineNodesNumRequired}
              placeholder="点位数"
              className="line-nodes-num"
              value={line_nodes_num}
              onChange={ele => this.onNameChange(ele, 'line_nodes_num')}
              pattern={regulars.NUMBER}
              disabled={this.disabled}
            />
          </div>
        )}
        {lineDistanceShow && (
          <div className="info-form-item line-distance">
            <Label isRequired={lineDistanceRequired}>车线里程</Label>
            <Input
              required={lineDistanceRequired}
              placeholder="车线里程"
              className="line-distance"
              value={lineDistance}
              onChange={ele => this.onNameChange(ele, 'line_distance')}
              pattern={regulars.FLOAT_P_3}
              disabled={this.disabled}
            >
              <Icon
                iconType="icon-calculate"
                onClick={() => !this.disabled && this.calcDistance(this.state.postInfo, true)}
              />
            </Input>
          </div>
        )}
        {lineTimeShow && (
          <div
            className={+this.hour_rule_value === 1 ? 'info-form-item line-time-hour' : 'info-form-item line-time-min'}
          >
            <Label isRequired={lineTimeRequired}>车线时效</Label>
            <Input
              required={lineTimeRequired}
              pattern={regulars.FLOAT_P_1}
              placeholder="车线时效"
              className="line-time"
              value={lineHour}
              onChange={ele => this.onNameChange(ele, 'line_time')}
              disabled={this.disabled}
            />
          </div>
        )}
        {standardSpeedShow && (
          <div className="info-form-item standard-speed">
            <Label isRequired={standardSpeedRequired}>标准时速</Label>
            <Input required={standardSpeedRequired} className="standard_speed" value={standardSpeed} disabled />
            <Icon
              iconType="icon-help"
              tipsCls="standard-speed-help"
              tips="作为考核车辆是否晚点的依据，标准时速=车线里程/标准时长"
            />
          </div>
        )}
        {receiptHourShow && (
          <div className={this.hour_rule_value === 1 ? 'info-form-item receipt-hour' : 'info-form-item receipt-min'}>
            <Label isRequired={receiptHourRequired}>回单时效</Label>
            <Input
              required={receiptHourRequired}
              pattern={regulars.FLOAT_P_1}
              placeholder="回单时效"
              className="receipt-hour"
              value={receipt_hour}
              onChange={ele => this.onNameChange(ele, 'receipt_hour')}
              disabled={this.disabled}
            />
          </div>
        )}
        {remarkShow && (
          <div className="info-form-item remark">
            <Label isRequired={remarkRequired}>备注信息</Label>
            <TextArea
              placeholder=""
              required={remarkRequired}
              classname="line-text-remark"
              defaultValue={remark}
              onChange={ele => this.onNameChange(ele, 'remark')}
              disabled={this.disabled}
            />
          </div>
        )}
      </div>
    );
  };
  // =================================== 货物表格 ===================================
  // 添加货物
  goodsAdd = () => {
    if (this.disabled) {
      return;
    }
    const { goods_list } = this.state;
    const goodsList = [...goods_list, defGoods];
    this.setState({
      goods_list: goodsList,
    });
  };
  // 减少货物
  goodsMinus = index => {
    if (this.disabled) {
      return;
    }
    const { goods_list } = this.state;
    const goodsList = [...goods_list];
    goodsList.splice(index, 1);
    this.setState({ goods_list: goodsList });
  };
  // 货物信息修改
  goodsChange = (index, key, val) => {
    const value = val && val.target ? val.target.value : val;
    const { goods_list } = this.state;
    const goodsList = [...goods_list];
    // 更新的数据
    const obj = {
      [key]: value,
    };
    // 要更新的货物数据
    goodsList[index] = {
      ...goodsList[index],
      ...obj,
    };

    this.setState({
      goods_list: goodsList,
    });
  };

  // 货物信息表格
  renderGoodsTable = () => {
    const { goods_list } = this.state;
    const pkg_enums = window.company_setting.pkg_name.selc_value;
    const goods_cat_enums = window.company_setting.goods_cat.selc_value;
    const { show, required, className } = this.fieldSet;
    const { gNameShow, gTypeShow, gPkgShow, gNumShow, gWeightShow, gVolumeShow } = show;
    const { gNameRequired, gTypeRequired, gPkgRequired, gNumRequired, gWeightRequired, gVolumeRequired } = required;
    const { gNameClass, gTypeClass, gPkgClass, gNumClass, gWeightClass, gVolumeClass } = className;

    return (
      <div className="goods-table-area">
        <table className="fn-table-a goods-table">
          <thead>
            <tr>
              <th className="w-4">
                <i className="fn-icon fn-icon-add-rad" onClick={this.goodsAdd} />
              </th>
              <th className="w-4">序号</th>
              {gNameShow && <th className={gNameClass}>货物名称</th>}
              {gTypeShow && <th className={gTypeClass}>货物类型</th>}
              {gPkgShow && <th className={gPkgClass}>包装</th>}
              {gNumShow && <th className={gNumClass}>件数</th>}
              {gWeightShow && <th className={gWeightClass}>重量(kg)</th>}
              {gVolumeShow && <th className={gVolumeClass}>体积(m³)</th>}
            </tr>
          </thead>
          <tbody>
            {goods_list.map((item, index) => (
              <tr>
                <td>
                  <i className="fn-icon fn-icon-minus-rad" onClick={() => this.goodsMinus(index)} />
                </td>
                <td>{index + 1}</td>
                {/* ====== 货物名称 ====== */}
                {gNameShow && (
                  <td>
                    <Input
                      className="goods-name"
                      required={gNameRequired}
                      value={item.g_name}
                      onChange={value => this.goodsChange(index, 'g_name', value)}
                      disabled={this.disabled}
                    />
                  </td>
                )}
                {/* ====== 货物类型 ====== */}
                {gTypeShow && (
                  <td>
                    <Select
                      className="goods-type"
                      required={gTypeRequired}
                      format="value"
                      filter="value"
                      header={['value']}
                      data={goods_cat_enums}
                      value={item.g_type}
                      onChange={value => this.goodsChange(index, 'g_type', value)}
                      disabled={this.disabled}
                    />
                  </td>
                )}
                {/* ====== 货物包装 ====== */}
                {gPkgShow && (
                  <td>
                    <Select
                      className="goods-pkg"
                      required={gPkgRequired}
                      format="value"
                      filter="value"
                      header={['value']}
                      data={pkg_enums}
                      value={item.g_pkg}
                      onChange={value => this.goodsChange(index, 'g_pkg', value)}
                      disabled={this.disabled}
                    />
                  </td>
                )}
                {/* ====== 默认件数 ====== */}
                {gNumShow && (
                  <td>
                    <Input
                      className="goods-num"
                      required={gNumRequired}
                      value={item.g_num}
                      onChange={value => this.goodsChange(index, 'g_num', value)}
                      disabled={this.disabled}
                    />
                  </td>
                )}
                {/* ====== 默认重量 ====== */}
                {gWeightShow && (
                  <td>
                    <Input
                      className="goods-weight"
                      required={gWeightRequired}
                      value={item.g_weight}
                      onChange={value => this.goodsChange(index, 'g_weight', value)}
                      disabled={this.disabled}
                    />
                  </td>
                )}
                {/* ====== 默认体积 ====== */}
                {gVolumeShow && (
                  <td>
                    <Input
                      className="goods-volume"
                      required={gVolumeRequired}
                      value={item.g_volume}
                      onChange={value => this.goodsChange(index, 'g_volume', value)}
                      disabled={this.disabled}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  onCloseStdLineDialog = ({ type, props }) => {
    if (type === 'success') {
      // 刷新routeCard
      // this.handleHide();
      // this.props.that && setTimeout(this.props.that.handleRefreshData, 2000);
      this.routeCardRef.current?.refresh(props.orderNumber);
    }
  };
  // 渲染路线卡片
  renderRouteCard = () => {
    const { line_nodes } = this.props?.dataAM?.line_info || { line_nodes: [] };
    const { parm } = this.props;
    const { lineId, opType } = parm;
    return (
      <div className="route-card-table-area">
        <RouteCard
          ref={this.routeCardRef}
          lineId={lineId}
          lineNodes={line_nodes}
          opType={this.opType}
          onClose={this.onCloseStdLineDialog}
        />
      </div>
    );
  };

  nodeInformation = val => {
    this.setState({
      switchVal: val,
    });
  };
  renderContent = () => {
    const { nodeCard, lineCard, goodsCard, routeCard } = this.state.card;
    const { switchVal } = this.state;
    const { show } = this.fieldSet;
    const nodeField = [
      'node_type',
      'node_name',
      'node_address',
      'addr_remark',
      'contractor',
      'contract_phone',
      'node_distance',
      'node_hour',
      'effect_radius',
      'fence_radius',
      'fence_extra',
    ];
    const lineField = [
      'line_num',
      'line_name',
      'line_type',
      'company_id',
      'use_corp_type',
      'customer_proj_id',
      'project_id',
      'line_distance',
      'line_time',
      'standard_speed',
      'receipt_hour',
      'remark',
    ];
    const goodsField = ['g_name', 'g_type', 'g_pkg', 'g_num', 'g_weight', 'g_volume'];

    const nodeFieldShow = nodeField.some(key => show[formatCamelCase(`${key}_show`)]);
    const lineFieldShow = lineField.some(key => show[formatCamelCase(`${key}_show`)]);
    const goodsFieldShow = goodsField.some(key => show[formatCamelCase(`${key}_show`)]);
    return (
      <div className="fn-card__form fn-card-form-line" ref={ref => (this.wrap = ref)}>
        {/* ============ 节点信息 ============ */}
        {nodeFieldShow && (
          <div className="set-card">
            <div className={getCardHeaderClass(nodeCard)}>
              <Icon iconType="icon-carry-out" onClick={() => this.setCard('nodeCard', !nodeCard)} />
              <div className="card-title">
                <span className="normal"> 节点信息 </span>
              </div>
              <Tips title="关闭可以先只创建线路信息，线路不可用" className="switch-tips">
                <Switch wrapClass="item-switch-wrap" checked={switchVal} onClick={this.nodeInformation} />
              </Tips>
            </div>
            <div className={getCardBodyClass(nodeCard, 'node')}>{this.renderTable()}</div>
          </div>
        )}
        {/* ============ 线路信息 ============ */}
        {lineFieldShow && (
          <div className="set-card">
            <div className={getCardHeaderClass(lineCard)}>
              <Icon iconType="icon-carry-out" onClick={() => this.setCard('lineCard', !lineCard)} />
              <div className="card-title">
                <span className="normal"> 线路信息 </span>
              </div>
            </div>
            <div className={getCardBodyClass(lineCard, 'line')}>{this.renderLine()}</div>
          </div>
        )}
        {/* ============ 货物信息表格 ============ */}
        {goodsFieldShow && (
          <div className="set-card">
            <div className={getCardHeaderClass(goodsCard)}>
              <Icon iconType="icon-carry-out" onClick={() => this.setCard('goodsCard', !goodsCard)} />
              <div className="card-title">
                <span className="normal"> 货物信息 </span>
              </div>
            </div>
            <div className={getCardBodyClass(goodsCard, 'goods')}>{this.renderGoodsTable()}</div>
          </div>
        )}
        {/* ============ 标准线路 ============ */}
        {this.isShowRouterCard && (
          <div className="set-card">
            <div className={getCardHeaderClass(routeCard)}>
              <Icon iconType="icon-carry-out" onClick={() => this.setCard('routeCard', !routeCard)} />
              <div className="card-title">
                <span className="normal"> 标准线路 </span>
                <DaoHangTip />
              </div>
            </div>
            <div className={getCardBodyClass(routeCard, 'route')}>{this.renderRouteCard()}</div>
          </div>
        )}
      </div>
    );
  };
  renderFooter = () =>
    !this.disabled && (
      <div>
        <div className="slide__drager__footer__content">
          <Button loading={this.state.isLoading} onClick={this.submitData} type="primary">
            确定
          </Button>
          <Button onClick={this.handleHide}>取消</Button>
        </div>
      </div>
    );

  render() {
    const { parm = {} } = this.props;
    const { titleName } = parm;
    return (
      <SlideDrager
        isShow
        dragerContainer={this.props.popContainer}
        togglePopWidth={this.props.togglePopWidth}
        ref={r => (this.slideAM = r)}
        slideWidth={1200}
        close={this.props.close}
        HeaderTitle={titleName}
        contentDiv={this.renderContent()}
        footerDiv={this.renderFooter()}
      />
    );
  }
}
export default withPopUpI18n(LineOperat);

/* eslint-disable no-return-assign */
/* eslint-disable require-atomic-updates */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ModalDialog, Button, Icon, PureCheckbox as CheckBox } from 'components';
import { fetchApi, fetchJAVA, showInfo, animateClass, fadeOut, getPageKey, throttle, EventListener } from 'utils';
import { REQUEST_PROC_STATUS, WARN, ERROR, INFO, CHECK } from 'constants';
import { prefixCls } from './index.scss';
import Table from './SimpleTable';
import _ from 'lodash';
import { isSettleFun } from 'pages/CarryController/listOp/utils';

const BatchOpConf = require('./mockData');

class BatchOp extends PureComponent {
  static defaultProps = {
    title: '批量操作',
    orderType: '批次',
    pace: 1,
    prefixalTips: '以下运单',
    getParaType: 'req',
    maxInterval: -1,
    minInterval: 300,
    tableWidth: 520,
    brTips: false,
    noNums: false, // 只提示后端返回的，不做运单号拼接处理
    defineClass: '',
    dealHeaderWidth: 100,
    // tableWrapStyle: { width: '520px' }
    closeLoading: false,
    manual: false,
    usePreviewPaymode: false,
    showFilterFailCheckBox: false,
    previewPaymodeKey: 'pay_info',
    isStatementPayment: false,
  };
  static propTypes = {
    isJAVA: PropTypes.bool,
    title: PropTypes.string,
    tips: PropTypes.string,
    prefixalTips: PropTypes.string,
    // 批次 或者 运单
    orderType: PropTypes.string,
    // 显示的header信息
    header: PropTypes.object,
    // 操作的数据行
    data: PropTypes.array,
    // 请求参数
    request: PropTypes.object,
    // 处理参数回调
    getPara: PropTypes.func,
    // getPara 返回的是body.req 还是body
    getParaType: PropTypes.objectOf(['req', 'body']),
    classname: PropTypes.string,
    // 弹框上的属性
    dialogProps: PropTypes.object,
    // 每次发送几个批次号
    pace: PropTypes.number,
    // 当前处理的index
    index: PropTypes.number,
    // 请求数据的
    requestUrl: PropTypes.string,
    close: PropTypes.func,
    // 弹窗关闭时的回调
    finishCallback: PropTypes.func,
    // 单步成功时的回调
    paceCallback: PropTypes.func,
    // 每一步完成时的回调，
    paceComplete: PropTypes.func,
    // 弹窗关闭时的回调
    customDealMultiRes: PropTypes.func,
    // 多步操作返回数据时的回调
    customSendRequest: PropTypes.func,
    // 自定义单条请求，返回Promise
    pacesCallback: PropTypes.func,
    // 请求间最大时间间隔（小于等于0 时 不设置定时器）
    maxInterval: PropTypes.number,
    // 最小时间间隔
    minInterval: PropTypes.number,
    tableWrapStyle: PropTypes.any,
    rowHeight: PropTypes.any,
    tableWidth: PropTypes.number,
    bodyCellStyle: PropTypes.any,
    indexCellStyle: PropTypes.any,
    grayElem: PropTypes.any, // 进度条与标题之间的文案提示
    suffixProcessRender: PropTypes.func, // 进度条后面的自定义提示内容
    brTips: PropTypes.bool, // 进度条结果的提示信息是否要换行显示
    operateWarn: PropTypes.any,
    getBatchOpRef: PropTypes.func,
    hideCarBatchTips: PropTypes.bool, // 处理状态不显示批次号
    closeLoading: PropTypes.bool, // 关闭按钮loading
    manual: PropTypes.bool,
    footerRender: PropTypes.func,
    getGroupBatchPace: PropTypes.func,
    usePreviewPaymode: PropTypes.bool, // 用于批量结算时，使用结算预览里的收支方式
    showFilterFailCheckBox: PropTypes.bool, // 是否展示只查看失败数据的checbox
    cancelTips: PropTypes.string, // 自定义取消Tips
    previewPaymodeKey: PropTypes.string, // 使用预览收支方式对应的key值
    isStatementPayment: PropTypes.bool, // 是否来自付款单支付
  };

  constructor(prop) {
    super(prop);
    this.state = {
      index: 0,
      sucNum: 0,
      failNum: 0,
      // 所有的请求是否处理完
      isFinish: false,
      // 是否处于暂停状态
      isStop: false,
      data: prop.data,
      ready: !this.props.manual,
      showErrorsVal: false,
    };
    this.totalNum = (prop.data || []).length;
    this.pace = prop.pace;
    this.header = this.dealHeader(prop.header);
    this.enumerations = prop.enumerations;
    this.requestUrl = prop.requestUrl;
    this.isWaitRes = false;
    this.pageKey = (getPageKey() || {}).pageConfig.key;
    //  分组批量支付每组支付数
    this.currentGroupBatchPace = 1;
    this.bindKeyDown();
  }

  componentDidMount = () => {
    console.log(this.header);
    // 开始处理请求
    console.time('dealRequest');
    if (!this.props.manual) {
      this.dealRequest();
    }
    // 关闭方法
    window.pageCloseFunc = window.pageCloseFunc || {};
    if (this.pageKey) {
      window.pageCloseFunc[this.pageKey] = window.pageCloseFunc[this.pageKey] || [];
      window.pageCloseFunc[this.pageKey].push(this.closeTips);
    }
    this.props.getBatchOpRef && this.props.getBatchOpRef(this);
  };

  componentWillUnmount() {
    this.keydownListener && this.keydownListener.remove();
    if (this.pageKey && window.pageCloseFunc) {
      window.pageCloseFunc[this.pageKey] = (window.pageCloseFunc[this.pageKey] || []).filter(x => x !== this.closeTips);
    }
    return false;
  }

  bindKeyDown = () => {
    !this.keydownListener &&
      (this.keydownListener = EventListener.listen(document, 'keydown', event => {
        if (event.keyCode === 116) {
          event.preventDefault();
          this.closeTips(() => location.reload()) && location.reload();
        }
      }));
  };
  closeTips = callback => {
    if (this.state.isFinish) {
      this.handleDialogClose();
      return true;
    }
    this.setState({ isStop: true });
    const closeFunc = () => {
      this.setState({ isStop: false }, () => {
        !this.isWaitRes && this.dealRequest();
      });
    };
    const content = (
      <div>
        <p style={{ fontSize: '14px' }}>{`您正在操作批量${this.props.tips}，确定要刷新页面吗？`}</p>
        <p style={{ color: '#999', marginTop: '10px' }}>刷新后</p>
        <p
          style={{ marginLeft: '20px', color: '#999' }}
        >{`正在提交的${this.props.tips}请求将被取消，页面将被刷新，之前已${this.props.tips}的${this.props.orderType}无法逆转，所有未${this.props.tips}的${this.props.orderType}都将取消。`}</p>
      </div>
    );
    showInfo(WARN, content, true, {
      confirm: () => {
        this.handleFinish();
        callback && callback();
      },
      cancel: closeFunc,
      closableCallback: closeFunc,
    });
    return false;
  };
  dealHeader = header => {
    Object.keys(header).forEach(x => Object.assign(header[x], { flexGrow: 1, align: 'left' }));
    const newHeader = {
      index: { title: '序号', type: 'Text', display: 'show', width: 45, align: 'center' },
      ...header,
      status: {
        title: '处理状态',
        type: 'Ccombiner',
        display: 'show',
        width: this.props.dealHeaderWidth,
        align: 'left',
      },
    };
    newHeader.status.data = { status_icon: { type: 'Icon' }, status_text: { type: 'Label' } };
    return newHeader;
  };
  handleDialogClose = () => {
    // this.opDialog && this.opDialog.handleHide()
    this.props.finishCallback && this.props.finishCallback({ opData: this.getTableData(), sucNum: this.state.sucNum });
    if (typeof this.props.close === 'function') {
      animateClass(this.dialogWrap, fadeOut, () => this.props.close());
    } else {
      animateClass(this.dialogWrap, fadeOut);
    }
  };
  handleHideCallback = () => (this.state.isFinish ? this.handleFinish() : this.handleButtonClick());
  getTableData = () => {
    const { data } = this.state;
    // this.opTable && (data = (this.opTable.getTableData() || {}).data || [])
    // data.forEach((x, index) => Object.assign(x, { index: index + 1 }))
    return data;
  };

  updateState = proxy => this.setState(proxy); // 外部更新state & 非状态列（有状态）

  setDataProps = (data, value) => {
    const newData = data;
    newData.otherProps = newData.otherProps || {};
    newData.otherProps.status_text = newData.otherProps.status_text || {};
    newData.otherProps.status_text.otherProps = { classname: value };
    return newData;
  };
  setTableData = data => {
    this.setState({
      data,
    });
  };
  getData = () => {
    // let data = this.getTableData(),
    //   currIndex = this.state.index

    const { index: currIndex, data } = this.state;
    let { pace } = this;
    if (this.props.getGroupBatchPace) {
      pace = this.props.getGroupBatchPace(currIndex, pace);
      this.currentGroupBatchPace = pace;
    }
    const requestData = data.slice(currIndex, currIndex + pace);
    let iter = 0;
    for (; iter < pace; iter++) {
      if (!data[currIndex + iter]) break;
      data[currIndex + iter].status_icon = 'icon-info-o';
      data[currIndex + iter].status_text = REQUEST_PROC_STATUS.doing;
      data[currIndex + iter].operations = this.props.operations && this.props.operations[currIndex + iter];
      data[currIndex + iter] = this.setDataProps(data[currIndex + iter], 'doing');
    }
    this.setState({ index: currIndex + iter, data: [...data] });
    // this.opTable && this.opTable.resetData(data)
    console.log('requestData', requestData);
    return requestData;
  };
  // 单条请求
  dealSingleResStatus = async (resData, reqIndex, dataSource, extData) => {
    let sucNum;
    let failNum;
    let data;
    const { brTips } = this.props;
    // 单条的时候
    if (!dataSource) {
      ({ sucNum, failNum, data } = this.state);
    } else {
      // 多条的时候
      ({ sucNum, failNum, data } = dataSource);
    }
    this.props.paceComplete && this.props.paceComplete(resData, reqIndex, extData);
    if (
      resData.errno !== 0 ||
      (resData.errno !== 0 &&
        (!resData.res.success_id || !resData.res.success_id.length) &&
        resData.res.failed_detail &&
        resData.res.failed_detail.length > 0)
    ) {
      failNum += 1;
      data[reqIndex].status_icon = 'icon-error-o';
      data[reqIndex].status_text = REQUEST_PROC_STATUS.fail;
      data[reqIndex] = this.setDataProps(data[reqIndex], 'fail');
      data[reqIndex].otherProps.tooltips = {};
      if (resData.errmsg) {
        data[reqIndex].otherProps = data[reqIndex].otherProps || {};
        data[reqIndex].otherProps.tooltips = data[reqIndex].otherProps.tooltips || {};
        data[reqIndex].otherProps.tooltips.status = resData.errmsg || '';
      }
      const tips = {};
      let tipsStr = '';
      const failed = (resData.res && resData.res.failed_detail) || [];
      failed.forEach(item => {
        tips[item.msg] = tips[item.msg] || [];
        item.number && tips[item.msg].push(item.number);
      });
      const getArrayNumTips = list => `${list.slice(0, 10).join(', ')}${list.length > 10 ? '等' : ''}`;

      if (!brTips) {
        Object.keys(tips).forEach(key => {
          if (this.props.noNums) {
            tipsStr = `${key} `;
          } else {
            tipsStr = `${tipsStr}${this.props.prefixalTips}${key}: ${getArrayNumTips(tips[key])} `;
          }
        });
        const { status } = data[reqIndex].otherProps.tooltips;
        let newStatus = '';
        if (this.props?.btnKey === 'customerSettle') {
          // 任务对账单列表结算按钮
          newStatus = tipsStr;
        } else {
          newStatus = (status ? `${status}${tipsStr ? '，' : ''}` : '') + tipsStr;
        }
        data[reqIndex].otherProps.tooltips.status = newStatus.includes('<br') ? (
          <span dangerouslySetInnerHTML={{ __html: newStatus }} />
        ) : (
          newStatus
        );
      } else {
        const tipsReasons = Object.keys(tips);

        if (tipsReasons.length) {
          tipsStr = (
            <div className="tips-br-wrap">
              {tipsReasons.map((itemKey, index) => (
                <p key={itemKey}>
                  {this.props.hideCarBatchTips
                    ? `${this.props.prefixalTips}${itemKey}`
                    : `${this.props.prefixalTips}${itemKey}: ${getArrayNumTips(tips[itemKey])} `}
                </p>
              ))}
            </div>
          );
          data[reqIndex].otherProps.tooltips.status = tipsStr;
        }
      }
    } else if (
      resData.errno === 0 &&
      (!resData.res || resData.res.failed_detail === undefined || Object.values(resData.res.failed_detail).length === 0)
    ) {
      sucNum += 1;
      data[reqIndex].status_icon = 'icon-check-o';
      data[reqIndex].status_text = REQUEST_PROC_STATUS.suc;
      data[reqIndex] = this.setDataProps(data[reqIndex], 'suc');
      this.props.paceCallback &&
        (await this.props.paceCallback(resData, extData || { originRes: resData, index: reqIndex }));
    } else if (resData.res && resData.res.failed_detail && Object.values(resData.res.failed_detail).length !== 0) {
      // 部分失败的情况
      const tips = {};
      let tipsStr = '';
      const failed = resData.res.failed_detail || [];
      failed.forEach(item => {
        tips[item.msg] = tips[item.msg] || [];
        item.number && tips[item.msg].push(item.number);
      });
      const getArrayNumTips = list => `${list.slice(0, 10).join(', ')}${list.length > 10 ? '等' : ''}`;
      Object.keys(tips).forEach(key => {
        tipsStr = this.props.hideCarBatchTips
          ? `${tipsStr}${this.props.prefixalTips}${key}`
          : `${tipsStr}${this.props.prefixalTips}${key}: ${getArrayNumTips(tips[key])} `;
      });
      sucNum += 1;
      data[reqIndex].status_icon = 'icon-warn-o';
      data[reqIndex].status_text = REQUEST_PROC_STATUS.part_fail;
      data[reqIndex] = this.setDataProps(data[reqIndex], 'part_fail');
      if (tipsStr) {
        data[reqIndex].otherProps = data[reqIndex].otherProps || {};
        data[reqIndex].otherProps.tooltips = data[reqIndex].otherProps.tooltips || {};
        data[reqIndex].otherProps.tooltips.status = tipsStr || '';
      }
    }
    // 多条的时候从外面统一setState
    if (!dataSource) {
      // this.opTable && this.opTable.resetData(data)
      this.setState({ sucNum, failNum, data: [...data] });
    }
    return data;
  };
  // 多条请求
  dealMultiResStatus = async (resData, reqIndex, propsPace) => {
    const index = reqIndex;
    const pace = propsPace ?? this.pace;
    if (this.props.customDealMultiRes) {
      await this.props.customDealMultiRes({ resData, reqIndex, that: this });
    } else {
      // TODO 统一的multi逻辑
      const indexs = [];
      const tableData = this.getTableData(); // 批量操作弹框中表格里面的数据
      // const tableDataLength = this.getTableData().length
      for (let i = 0; i < pace; i++) {
        if (+index + i < tableData.length) indexs.push(+index + i);
      }
      const resDatas = this.props.pacesCallback && this.props.pacesCallback(resData, indexs, tableData);
      let { sucNum, failNum } = this.state;
      const { data } = this.state;
      // const data = this.getTableData()
      const dataSource = { sucNum, failNum, data };
      indexs.forEach(async item => {
        dataSource.data = await this.dealSingleResStatus(resDatas[item], item, dataSource, {
          originRes: resData,
          index: item,
        });
      });
      indexs.forEach(item => {
        if (resDatas[item].errno !== 0) {
          failNum += 1;
        } else {
          sucNum += 1;
        }
      });
      this.setState({
        sucNum,
        failNum,
        data: [...dataSource.data],
      });
      // this.opTable && this.opTable.resetData(dataSource.data)
      // let sucNum = this.state.sucNum, failNum = this.state.failNum, data = this.state.data
      // let sucId = resData.suc_id
      // if (resData.errno === 0) {
      // }
      // if (resData.errno !== 0) {
      // }
    }
  };
  run = () => {
    this.setState(
      {
        ready: true,
      },
      () => {
        this.dealRequest();
      },
    );
  };
  cancel = () => {
    this.setState({}, () => {
      this.handleButtonClick();
    });
  };
  close = () => {
    this.setState({}, () => {
      this.handleFinish();
    });
  };
  // 发送请求
  dealRequest = async () => {
    if (this.state.isStop || !this.state.ready) return;
    if (this.state.index >= this.totalNum || this.state.isFinish) {
      this.setState({ isFinish: true });
      return false;
    }
    const reqIndex = this.state.index;
    const requestData = this.getData();
    const currentGroupBatchPace = this.props.getGroupBatchPace ? this.currentGroupBatchPace : this.pace;
    const isGroupBatch = !!this.props.getGroupBatchPace;
    const param = await this.props.getPara(requestData, isGroupBatch);
    if ((isSettleFun(this.props.btnKey) && !isGroupBatch) || this.props?.isStatementPayment) {
      param.settle_id = this.props.settle_id;
    }
    // 批量结算时(司机车辆借支结算)，使用结算预览里的收支方式
    if (this.props.usePreviewPaymode && param?.custom_pay_info) {
      const { custom_pay_info, data } = param;
      if (data.length) {
        const { bill_id } = data[0];
        let pay_info = null;
        if (bill_id) {
          pay_info = custom_pay_info[bill_id];
        }
        // 如果取到id 对应的收支方式，则使用
        if (pay_info) {
          const payInfoKey = this.props.previewPaymodeKey;
          param[payInfoKey] = pay_info;
        }
      }
      delete param.custom_pay_info;
    }

    const requestUrl = this.props.getUrl ? this.props.getUrl(requestData) : this.requestUrl;
    const { getParaType: paraType, maxInterval, minInterval } = this.props;
    const body = paraType === 'req' ? { req: param } : param;
    if (param instanceof FormData) {
      // 建议在  getPara 直接包含is_batch_op，这里仅用过第二次兜底，最后也不会出现在返回值的reg中。
      param.append('is_batch_op', true); // 仅用来标识 是否 通过批量弹框操作的，后端不会使用
    }
    param.batch_op_flag = true; // 区分是否走的批量弹框操作
    param.is_batch_op = true; // 仅用来标识 是否 通过批量弹框操作的，后端不会使用

    this.isWaitRes = true;
    const timer = maxInterval > 0 && setTimeout(this.dealRequest, maxInterval);
    // credentials 批量登记异常等接口需要携带cookie
    const fetch = this.props.isJAVA ? fetchJAVA : fetchApi;
    const resHandle = async resData => {
      this.isWaitRes = false;
      // this.pace === 1 && this.dealSingleResStatus(resData, reqIndex)
      if (this.pace === 1) {
        await this.dealSingleResStatus(resData, reqIndex);
      } else if (this.pace > 1) {
        await this.dealMultiResStatus(resData, reqIndex, currentGroupBatchPace);
      }
      let timeInterval = minInterval;
      resData.errno === -23 && (timeInterval = 1000);
      // 如果timer还未触发，干掉它
      if (timer) {
        clearTimeout(timer);
      }
      throttle(() => this.dealRequest(), timeInterval)();
    };
    if (param.error) {
      return resHandle({ errmsg: param.error, errno: 404, res: {} });
    }

    const request = this.props.customSendRequest
      ? this.props.customSendRequest(reqIndex, param)
      : fetch(requestUrl || this.requestUrl, { method: 'POST', body, credentials: 'include' });

    request
      .then(resHandle, async resData => {
        if (timer) {
          clearTimeout(timer);
        }
        // let errmsg = (resData.status === 504) ? '请求超时' : '处理失败'
        this.pace === 1 && (await this.dealSingleResStatus({ errno: -1, errmsg: '请求超时，请稍后重试' }, reqIndex));

        throttle(() => this.dealRequest(), 500)();
      })
      .catch(e => {
        if (e?.statusText === 'Gateway Time-out') {
          showInfo(ERROR, '接口超时，请稍后重试！');
        }
      });
  };

  renderGrayElem = () => {
    const { grayElem } = this.props;
    return grayElem || null;
  };
  // Procing status
  renderProcContent = () => {
    const { ready } = this.state;
    if (!ready) return null;
    let number = this.state.index;
    if (this.pace > 1) {
      // 在发送进行多个请求的最后一组请求时，顶部显示的number
      number -= (this.state.index - 1) % this.pace;
    }
    if (this.state.currentIdx) {
      number = this.state.currentIdx;
    }
    return (
      <div className={`${prefixCls}__tip`}>
        <div>
          <Icon iconType={INFO} />
        </div>
        <div className="batch-tip-num-txt">
          <span>{`批量${this.props.tips}进度（第`}</span>
          <span className="spe-font" style={{ color: '#FF9A00' }}>
            {number}
          </span>
          <span>个/共</span>
          <span>{this.totalNum}</span>
          <span>个），</span>
        </div>
        <div style={{ color: '#F12222' }}>操作进行中，请勿关闭！</div>
        {this.props.suffixProcessRender?.({ ...this.state })}
      </div>
    );
  };
  // Proc end status
  renderProcEnd = () => {
    const { ready } = this.state;
    if (!ready) return null;
    console.timeEnd('dealRequest');
    return (
      <div className={`${prefixCls}__tip`}>
        <div>
          <Icon iconType={CHECK} />
        </div>
        <div className="batch-tip-num-txt">
          <span>批量操作已完成，</span>
          <span className="spe-font" style={{ color: '#F12222' }}>
            {this.state.failNum}
          </span>
          <span>个操作失败，</span>
          <span className="spe-font" style={{ color: '#1DBB73' }}>
            {this.state.sucNum}
          </span>
          <span>个操作成功！</span>
          {this.props.suffixProcessRender?.({ ...this.state })}
        </div>
      </div>
    );
  };
  renderDialogContent = () => {
    const { data, showErrorsVal } = this.state;
    const {
      tableWrapStyle,
      tableWidth,
      rowHeight,
      bodyCellStyle,
      indexCellStyle,
      defineClass,
      showFilterFailCheckBox,
    } = this.props;
    const { sucNum, failNum } = this.state;
    const dealNum = sucNum + failNum;
    const showData = showErrorsVal
      ? data.filter(item => _.get(item, 'otherProps.status_text.otherProps.classname') === 'fail')
      : data;
    return (
      <div className={`${prefixCls}__content`} style={tableWrapStyle}>
        {this.props.operateWarn}
        {this.state.isFinish && dealNum >= this.totalNum ? this.renderProcEnd() : this.renderProcContent()}
        {this.renderGrayElem()}
        {this.state.isFinish && showFilterFailCheckBox && (
          <label className="fn-checkbox-label" style={{ marginBottom: '10px' }}>
            <CheckBox checked={showErrorsVal} onChange={e => this.setState({ showErrorsVal: e.target.checked })} />
            只查看失败数据
          </label>
        )}
        <Table
          className={`${prefixCls}__table ${defineClass}`}
          isOrderNumberColumn={false}
          header={this.header}
          data={showData}
          width={tableWidth}
          rowHeight={rowHeight}
          bodyCellStyle={bodyCellStyle}
          indexCellStyle={indexCellStyle}
          enumerations={this.enumerations}
          refGetter={r => (this.opTable = r)}
        />
      </div>
    );
  };
  // 取消操作
  handleButtonClick = () => {
    console.log('handleButtonClick', this);
    if (!this.state.ready) {
      this.handleDialogClose();
      return;
    }
    if (this.state.isFinish) {
      if (this.props.closeLoading) {
        setTimeout(() => {
          this.handleDialogClose();
          this.setState({
            loading: false,
          });
        }, 2000);
        this.setState({
          loading: true,
        });
      } else {
        this.handleDialogClose();
      }
      return;
    }
    this.setState({ isStop: true });
    const closeFunc = () => {
      this.setState({ isStop: false }, () => {
        !this.isWaitRes && this.dealRequest();
      });
    };
    const content = (
      <div>
        <p style={{ fontSize: '14px' }}>{`您确定要取消批量${this.props.tips}吗？`}</p>
        <p style={{ color: '#999', marginTop: '10px' }}>取消后</p>
        <p style={{ marginLeft: '20px', color: '#999' }}>
          {this.props.cancelTips ??
            `正在提交的${this.props.tips}请求将被取消，之前已${this.props.tips}的${this.props.orderType}无法逆转，所有未${this.props.tips}的${this.props.orderType}都将取消。`}
        </p>
      </div>
    );
    showInfo(WARN, content, true, {
      confirm: () => this.handleFinish(),
      cancel: closeFunc,
      closableCallback: closeFunc,
    });
  };
  handleFinish = () => {
    this.setState({ isFinish: true }, this.handleDialogClose);
  };
  renderButtonList = () => {
    if (this.props.footerRender) return this.props.footerRender(this);
    const btnText = this.state.isFinish ? '关闭' : '取消';
    return (
      <div>
        <Button type="primary" key="cancel" loading={this.state.loading} onClick={this.handleButtonClick}>
          {btnText}
        </Button>
      </div>
    );
  };

  render() {
    const { classname, dialogProps } = this.props;
    return (
      <div className={`${prefixCls}__wrap ${classname}`} ref={d => (this.dialogWrap = d)}>
        <ModalDialog
          {...dialogProps}
          isShow
          closable
          autoDestroy
          ref={d => (this.opDialog = d)}
          title={this.props.title || ''}
          closableCallback={this.handleButtonClick}
          // contentStyle={{ minWidth: '360px' }}
          content={this.renderDialogContent()}
          bottom={this.renderButtonList()}
          // handleHideCallback={this.handleHideCallback}
        />
      </div>
    );
  }
}
export default BatchOp;

import { Icon, PureInput, CheckDrop, Select, Radio, UploadImgList } from 'components';
import React, { useState, useEffect } from 'react';
import { Table, Typography } from '@gui/web-react';
import { prefixCls } from './index.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { getCheckDropContent, getCheckDropVal } from 'pages/SettingController/set/tool';
import { formatCell } from './util';

/**
 * 适用于 CheckSet 的自定义hooks，用于处理数据格式。支持多级（2级）数据，支持枚举。
 * @param {*} config
 * @returns
 */
const useCheckSet = (config = {}) => {
  const {
    header = {},
    data,
    enumerations = {},
    disabled = false,
    childKey = 'child',
    onChange = () => {},
    beforeChange = ({ value }) => ({ value, pass: true }),
    ...otherProps
  } = config;
  const columns = [];
  const [state, setState] = useState(data);

  useEffect(() => {
    setState(data);
  }, [data]);

  const _setState = _data => {
    setState(_data);
    onChange?.(_data);
  };

  // header 字段
  const headerKeys = Object.keys(header);
  // 列的key
  const columnKeys = headerKeys.map(k => header[k].columnKey ?? header[k].dataIndex ?? k);

  const childKeyPrefix = `${childKey}.`;

  // 格式化数据，方便Table渲染
  const store = state?.reduce((pre, cur, index) => {
    const { [childKey]: child, ...parent } = cur;

    const parentIndex = index;
    pre.push(parent);
    const curIndex = pre.length - 1; // 本行在store中的索引

    const parentKeys = columnKeys.filter(k => !k.startsWith(childKeyPrefix));
    const childKeys = columnKeys.filter(k => k.startsWith(childKeyPrefix));

    // 设置字段的长度，方便合并单元格。
    // 父级字段长度等于子级数据长度，默认长度为1。子级字段均为1
    const parentFieldsLength = parentKeys.reduce((o, c) => ({ ...o, [`_${c}.length`]: child?.length ?? 1 }), {});
    const childFieldsLength = childKeys.reduce((o, c) => ({ ...o, [`_${c}.length`]: 1 }), {});

    Object.assign(parent, { _parentIndex: parentIndex, ...parentFieldsLength, ...childFieldsLength });

    // 将子项展开，跟父级同级，方便用于Table渲染
    child?.forEach((item, i) => {
      const expandObj = {
        ...childFieldsLength,
        _parentIndex: parentIndex, // 在原始数据的父级索引
        _childIndex: i, // 在原始数据的子级索引
      };
      Object.keys(item).forEach(k => {
        const expandKey = k === 'otherProps' ? 'otherProps' : `${childKeyPrefix}${k}`;
        expandObj[expandKey] = item[k];
      });

      // 如果是第一个子项，则直接赋值给父级
      if (i === 0) {
        Object.assign(parent, expandObj); // otherProps会覆盖父级
      } else {
        // 否则，将子项追加到父级后
        pre.push(expandObj);
      }
    });

    pre[curIndex] = parent; // 更新父级
    return pre;
  }, []);

  // 添加一行父级
  const onAdd = () => {
    _setState([...(state ?? []), {}]);
  };

  // 删除一行（父级或子级）
  const onDel = i => {
    const row = store[i];
    const _parentIndex = row._parentIndex;
    const parent = state[_parentIndex];
    const childIndex = row._childIndex ?? 0;
    // 没有子项，或只有一个子项，直接删除父级
    if (!parent[childKey]?.length || (parent[childKey].length === 1 && childIndex === 0)) {
      state.splice(_parentIndex, 1);
    } else {
      // 删除子项
      parent[childKey].splice(childIndex, 1);
    }
    _setState([...state]);
  };

  // 添加一行子级
  const onAddSub = i => {
    const row = store[i];
    const _parentIndex = row._parentIndex;
    const parent = state[_parentIndex];
    parent[childKey] = parent[childKey] ?? [];
    // 原本没有子级，与父级公用一个数组，需要默认一个
    if (parent[childKey].length === 0) {
      parent[childKey].push({});
    }
    // 新增一个子级
    parent[childKey].push({});
    setState([...state]);
  };

  // 修改单元格
  const handleChange = (i, record, columnKey, val) => {
    const newState = _.cloneDeep(state);
    const row = store[i];
    const _parentIndex = row._parentIndex;
    const parent = newState[_parentIndex];
    const childIndex = row._childIndex ?? 0;
    const { value, pass } = beforeChange({
      value: val,
      columnKey,
      rowIndex: i, // store中的索引
      store,
      record,
      // 关于父级的信息
      parent,
      parentIndex: _parentIndex,
      data: newState,
    });
    if (!pass) return;
    // 如果是子项，则设置子项的值
    if (columnKey.startsWith(childKeyPrefix)) {
      const childColumnKey = columnKey.replace(childKeyPrefix, '');
      _.set(parent, [childKey, childIndex, childColumnKey], value);
    } else {
      // 如果是父级，则设置父级的值
      _.set(parent, [columnKey], value);
    }

    _setState(newState);
  };

  // 自定义校验
  const validityWrap = (validityFn, i, record, columnKey, val) => {
    const newState = _.cloneDeep(state);
    const row = store[i];
    const _parentIndex = row._parentIndex;
    const parent = newState[_parentIndex];
    const childIndex = row._childIndex ?? 0;
    return validityFn({
      value: val,
      columnKey,
      rowIndex: i, // store中的索引
      store,
      record,
      // 关于父级的信息
      parent,
      parentIndex: _parentIndex,
      data: newState,
    });
  };

  headerKeys.forEach(key => {
    const headerCol = header[key];
    const columnKey = headerCol.columnKey ?? headerCol.dataIndex ?? key;

    switch (headerCol.type) {
      case 'Substract':
        columns.push({
          title: (
            <Icon
              iconType="icon-add-rad"
              disabled={disabled}
              onClick={() => !disabled && onAdd()}
              style={{ fontWeight: 300 }}
            />
          ),
          dataIndex: 'substract',
          width: 40,
          align: 'center',
          render: (col, record, index) => (
            <Icon iconType="icon-minus-rad" disabled={disabled} onClick={() => !disabled && onDel(index, record)} />
          ),
          ...headerCol,
        });
        break;
      case 'Index':
        columns.push({
          title: '序号',
          dataIndex: 'index',
          width: 40,
          align: 'center',
          render: (col, record, index) => <span>{++index}</span>,
          ...headerCol,
        });
        break;
      case 'SubInput':
        columns.push({
          dataIndex: columnKey,
          align: 'center',
          render: (col, record, index) => {
            const { otherProps: rowOtherProps = {} } = record;
            const cellOtherProps = rowOtherProps[columnKey] ?? {}; // 单元格其他属性，自定义单元格
            const cellKey = cellOtherProps.columnKey ?? cellOtherProps.dataIndex ?? columnKey; // 单元格数据key，默认取headerCol.columnKey
            const obj = {
              children: !cellOtherProps.hidden ? (
                <span className={classnames({ [`${prefixCls}_item-input`]: true })}>
                  <Icon
                    iconType="icon-add-rad"
                    disabled={disabled}
                    onClick={() => !disabled && onAddSub(index, record)}
                  />
                  <PureInput
                    disabled={disabled}
                    value={record[cellKey]}
                    required={cellOtherProps.required ?? headerCol.required}
                    customValidity={
                      headerCol.customValidity
                        ? val => validityWrap(headerCol.customValidity, index, record, columnKey, val)
                        : undefined
                    }
                    onChange={e => handleChange(index, record, cellKey, e?.target.value)}
                  />
                </span>
              ) : null,
              props: {},
            };

            const rowSpan = cellOtherProps.rowSpan ?? record[`_${columnKey}.length`] ?? 0; // 单元格rowSpan，可针对单元格自定义，默认取子级数组长度
            obj.props.rowSpan = rowSpan;
            return obj;
          },
          ...headerCol,
        });
        break;
      case 'PureInput':
        columns.push({
          dataIndex: columnKey,
          align: 'center',
          render: (col, record, index) => {
            const { otherProps: rowOtherProps = {} } = record;
            const cellOtherProps = rowOtherProps[columnKey] ?? {}; // 单元格其他属性，自定义单元格
            const cellKey = cellOtherProps.columnKey ?? cellOtherProps.dataIndex ?? columnKey; // 单元格数据key，默认取headerCol.columnKey
            const obj = {
              children: !cellOtherProps.hidden ? (
                <PureInput
                  disabled={disabled}
                  required={cellOtherProps.required ?? headerCol.required}
                  value={record[cellKey]}
                  customValidity={
                    headerCol.customValidity
                      ? val => validityWrap(headerCol.customValidity, index, record, columnKey, val)
                      : undefined
                  }
                  onChange={e => handleChange(index, record, cellKey, e?.target.value)}
                />
              ) : null,
              props: {},
            };

            const rowSpan = cellOtherProps.rowSpan ?? record[`_${columnKey}.length`] ?? 0; // 单元格rowSpan，可针对单元格自定义，默认取子级数组长度
            obj.props = { rowSpan };
            return obj;
          },
          ...headerCol,
        });
        break;
      case 'CheckDrop':
        columns.push({
          dataIndex: columnKey,
          align: 'center',
          render: (col, record, index) => {
            const { otherProps: rowOtherProps = {} } = record;
            const cellOtherProps = rowOtherProps[columnKey] ?? {}; // 单元格其他属性，自定义单元格
            const cellKey = cellOtherProps.columnKey ?? cellOtherProps.dataIndex ?? columnKey; // 单元格数据key，默认取headerCol.columnKey
            const { refEnum, header: checkDropHead } = headerCol;
            const enumData = refEnum && enumerations?.[refEnum] ? enumerations[refEnum] : {};
            const content = getCheckDropContent({
              saveData: record[cellKey],
              enumData,
            });
            const obj = {
              children: !cellOtherProps.hidden ? (
                <CheckDrop
                  disabled={disabled}
                  header={checkDropHead}
                  content={content}
                  required={cellOtherProps.required ?? headerCol.required}
                  onClick={val => handleChange(index, record, cellKey, getCheckDropVal(val))}
                />
              ) : null,
              props: {},
            };

            const rowSpan = cellOtherProps.rowSpan ?? record[`_${columnKey}.length`] ?? 0; // 单元格rowSpan，可针对单元格自定义，默认取子级数组长度
            obj.props = { rowSpan };
            return obj;
          },
          ...headerCol,
        });
        break;
      case 'Select':
        columns.push({
          dataIndex: columnKey,
          align: 'center',
          render: (col, record, index) => {
            const { otherProps: rowOtherProps = {} } = record;
            const cellOtherProps = rowOtherProps[columnKey] ?? {}; // 单元格其他属性，自定义单元格
            const cellKey = cellOtherProps.columnKey ?? cellOtherProps.dataIndex ?? columnKey; // 单元格数据key，默认取headerCol.columnKey
            const { refEnum, isMultiple } = headerCol;
            const enumData = refEnum && enumerations?.[refEnum] ? enumerations[refEnum] : [];
            const obj = {
              children: !cellOtherProps.hidden ? (
                <Select
                  disabled={disabled}
                  required={cellOtherProps.required ?? headerCol.required}
                  value={record[cellKey] ?? []}
                  data={enumData}
                  multiple={isMultiple}
                  onChange={val => handleChange(index, record, cellKey, val)}
                />
              ) : null,
              props: {},
            };

            const rowSpan = cellOtherProps.rowSpan ?? record[`_${columnKey}.length`] ?? 0; // 单元格rowSpan，可针对单元格自定义，默认取子级数组长度
            obj.props = { rowSpan };
            return obj;
          },
          ...headerCol,
        });
        break;
      case 'Radio':
        columns.push({
          dataIndex: columnKey,
          align: 'center',
          render: (col, record, index) => {
            const { otherProps: rowOtherProps = {} } = record;
            const cellOtherProps = rowOtherProps[columnKey] ?? {}; // 单元格其他属性，自定义单元格
            const cellKey = cellOtherProps.columnKey ?? cellOtherProps.dataIndex ?? columnKey; // 单元格数据key，默认取headerCol.columnKey
            const { refEnum, isMultiple } = headerCol;
            const enumData = refEnum && enumerations?.[refEnum] ? enumerations[refEnum] : [];
            const obj = {
              children: !cellOtherProps.hidden ? (
                <Radio
                  disabled={disabled}
                  required={cellOtherProps.required ?? headerCol.required}
                  value={record[cellKey] ?? ''}
                  data={enumData}
                  onClick={val => handleChange(index, record, cellKey, val)}
                />
              ) : null,
              props: {},
            };

            const rowSpan = cellOtherProps.rowSpan ?? record[`_${columnKey}.length`] ?? 0; // 单元格rowSpan，可针对单元格自定义，默认取子级数组长度
            obj.props = { rowSpan };
            return obj;
          },
          ...headerCol,
        });
        break;
      case 'UploadImgList':
        columns.push({
          dataIndex: columnKey,
          align: 'center',
          render: (col, record, index) => {
            const { otherProps: rowOtherProps = {} } = record;
            const cellOtherProps = rowOtherProps[columnKey] ?? {}; // 单元格其他属性，自定义单元格
            const cellKey = cellOtherProps.columnKey ?? cellOtherProps.dataIndex ?? columnKey; // 单元格数据key，默认取headerCol.columnKey
            const {
              label = '',
              required,
              ellipsis = 3,
              deletable = true,
              maxNum,
              imgType,
              hideIconWhenDisable,
            } = headerCol;
            const obj = {
              children: !cellOtherProps.hidden ? (
                <UploadImgList
                  maxNum={maxNum}
                  imgType={imgType}
                  outSideUpdate
                  disabledAll={disabled || cellOtherProps.disabled}
                  imgInfo={record[cellKey]}
                  onChange={val => handleChange(index, record, cellKey, val.imgInfo)}
                  labelText={label}
                  required={cellOtherProps.required ?? required}
                  ellipsis={ellipsis}
                  hideIconWhenDisable={hideIconWhenDisable}
                  viewRemove={deletable}
                />
              ) : null,
              props: {},
            };

            const rowSpan = cellOtherProps.rowSpan ?? record[`_${columnKey}.length`] ?? 0; // 单元格rowSpan，可针对单元格自定义，默认取子级数组长度
            obj.props = { rowSpan };
            return obj;
          },
          ...headerCol,
        });
        break;
      case 'Text':
        columns.push({
          dataIndex: columnKey,
          align: 'center',
          render: (col, record, index) => {
            const { otherProps: rowOtherProps = {} } = record;
            const cellOtherProps = rowOtherProps[columnKey] ?? {}; // 单元格其他属性，自定义单元格
            const cellKey = cellOtherProps.columnKey ?? cellOtherProps.dataIndex ?? columnKey; // 单元格数据key，默认取headerCol.columnKey
            const { rows = 1 } = headerCol;
            const { value, classname } = formatCell({
              columnKey: cellKey,
              type: 'Text',
              item: record,
              value: record[cellKey],
              enumerations,
              headerCol,
            });
            const obj = {
              children: !cellOtherProps.hidden ? (
                <Typography.Paragraph ellipsis={{ rows, showTooltip: true, wrapper: 'span' }} className={classname}>
                  {value}
                </Typography.Paragraph>
              ) : null,
              props: {},
            };

            const rowSpan = cellOtherProps.rowSpan ?? record[`_${columnKey}.length`] ?? 0; // 单元格rowSpan，可针对单元格自定义，默认取子级数组长度
            obj.props = { rowSpan };
            return obj;
          },
          ...headerCol,
        });
        break;
      default:
        columns.push({
          dataIndex: columnKey,
          align: 'center',
          ...headerCol,
        });
    }
  });

  return {
    ...otherProps,
    columns,
    data: store,
  };
};
const CheckSet = props => {
  const { columns, data, ...otherProps } = useCheckSet({ ...props });
  return (
    <Table
      className={classnames({ [`${prefixCls}`]: true })}
      pagination={false}
      hover={false}
      border={{
        wrapper: true,
        headerCell: true,
        bodyCell: true,
        cell: true,
      }}
      style={{ width: '100%' }}
      {...otherProps}
      columns={columns}
      data={data}
    />
  );
};

export default CheckSet;

import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import { prefixCls } from './index.scss';
import _ from 'lodash';
import { Table, CardBox, PopUp, PopTip, Icon } from 'components';
import { PICK_TABLE_ROW_HEIGHT, PICK_TABLE_HEADER_HEIGHT, ERROR } from 'constants';
import { postJAVA, confirmWithReasonSync, arraySum, toNegatives } from 'utils';
import { isAccountCheckPayMode } from 'pages/FinanceController/utils/accountBook';
import Big from 'big.js';
import { FLOAT_I_9_M_9_P_2, FLOAT_I_9_N_9_P_2 } from '../../../../constants/validate';
import { billAdjustTable, summary_header } from '../BiPickOrderContent/tableConfig';
import BiSummary from '../BiSummary';
import { analyzeFeeList, getFeelListByFeeRelation } from '../../../../pages/FinanceController/utils/feeDetailUtil';
import PayMode from 'components/commoncomponents/payMode';
import LedgerCard from '../../../../pages/FinanceController/ledgerCard';
import {
  accountTypeMap,
  gasPayModeAccountType,
  carAndDriverAndCustomerCashPayModeAccountType,
} from 'pages/FinanceController/constants/accountBook';
import { feeTipsConfig, feeTitleConfig } from 'pages/FinanceController/utils/getLayout';

/**
 * Look！！这里可以和 BiPickerOrderContent 进行复用，后面应该考虑迁移过来。
 * 我现在忙着 Q1 大战，没时间去迁移了。
 */

export default class BiBillAdjust extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      // 账单调整 表格
      billAdustInfo: {
        ...billAdjustTable,
      },
      isFiddle: prop.isFiddle,
      settleInfo: {
        customer_type: prop.customer_type,
      },
    };
    // 获取 账单调整 费用 下拉
    this.getAdjustFeeTypeList();
    prop.fetchFee && this.getFeeRelation();

    // 对账总金额初始值，仅用于记录判断值是否有变更
    this.beforeCheckTotalAmount = 0;
  }
  static defaultProps = {
    type: 'audit',
    opType: '审核',
    fetchFee: false, // 是否自动触发获取费用信息
    stackAdjust: false, // 叠加 账单调整。不回显原有的账单调整，新增的“账单调整费用”会叠加到现有的值上
    showAmountSummary: true, // 是否显示费用合计
    showSettleInfo: true, // 是否显示收支方式
    isFiddle: false, // 用于账单调整是否显示收支合计
  };

  // 获取 费用明细 树与数据(这个接口有点怪：费用类型树与费用明细数据均包含在这个接口中)
  getFeeRelation = async (...param) => {
    // const res = await postJAVA('/cmm-finance/fee/getFeeRelationTree', {});
    const res = await this.props.getFeeRelation?.();
    this.handleFeeRelation(res, ...param);
  };

  handleFeeRelation = res => {
    this._feeRelationAndFee = res;
    const feeDetailFlaten = getFeelListByFeeRelation(res);
    const oldBillAdustInfo = this.state.billAdustInfo;

    const adjustDataFromServer = feeDetailFlaten
      ?.map?.(item => ({
        amount: item.amount,
        fee_id: item.id,
        fee_type: item.type,
        expense: item.db_key,
        fee_direct: item.fee_direct,
        share_type: item.share_type,
        share_type_name: item.share_type_name,
      }))
      .filter?.(item => +item.fee_type == 17) // 只取 账单调整 费用项。
      .filter?.(item => item.amount || item.amount == 0); // 只取 有值的费用项

    this._adjustDataFromServer = adjustDataFromServer;

    this.setState(
      {
        oriFeeCatigoryTree: res,
        // eslint-disable-next-line react/no-unused-state
        feeCatigory: _.cloneDeep(feeDetailFlaten), // 类型树
        billAdustInfo: {
          ...oldBillAdustInfo,
          data: this.props.stackAdjust ? [...(oldBillAdustInfo?.data ?? [])] : adjustDataFromServer,
        },
      },
      () => this.handleChangeAmount?.(),
    );
  };
  // 格式化费用明细，用于展示
  formatAmountDetailList = amountDetail => {
    const { checked = {}, totalList = {} } = amountDetail || (this.props.amountDetail ?? {});
    const { feeCatigory = [], oriFeeCatigoryTree = [] } = this.state;
    const pickExpense = this.props.expense;
    const list = Object.entries(totalList).map(([key, value]) => {
      const expense = feeCatigory.find(item => item.db_key === key);
      return {
        key,
        expense: pickExpense?.[key]?.text ?? expense?.fee_name ?? '', // 优先取pickOrderInfo.expense中的费用名称（可能含有别名）,否则取 费用类型树 中的名称
        type: expense?.category_name,
        value: !expense ? 0 : value, // 不在 费用类型树 中的数据不显示。
        showCheckbox: this.props.type !== 'show',
        checked: checked[key],
        disabled: ['账单调整', '账单调整合计'].includes(expense?.category_name),
      };
    });

    // 费用类型排序。根据 费用类型树 获取字段及顺序，实际没在 费用类型树 中的费用也不会展示。
    const sort = oriFeeCatigoryTree?.map?.(item => item.category_name) ?? [];

    return list
      .map(item => {
        try {
          item.value = Big(item.value).toFixed(2);
        } catch {
          console.log('请输入数字');
        }
        return item;
      })
      .filter(item => item.value && item.value != 0) // 金额为 0 的费用，不显示
      .sort((a, b) => sort.findIndex(item => item === a.type) - sort.findIndex(item => item === b.type));
  };
  // 格式化费用信息合计，用于底部展示
  formateAmountDetailTotal = amountDetail => {
    const { checked = {}, totalList = {} } = amountDetail || (this.props.amountDetail ?? {});
    const { feeCatigory = [], oriFeeCatigoryTree = [] } = this.state;

    // 费用类型排序。根据 费用类型树 获取字段及顺序，实际没在 费用类型树 中的费用也不会展示。
    const expenseList = oriFeeCatigoryTree?.map?.(item => item.category_name) ?? [];

    expenseList.push('对账总金额');

    // 本地的 合计项 设置
    const summary_map = summary_header ?? {};

    const total_map = expenseList.reduce(
      (obj, key) => ({
        ...obj,
        [key]: {
          title: key.indexOf('合计') > 1 ? key : `${key || ''}合计`,
          color: '#222',
          value: 0,
          ...summary_map[key], // 使用 本地的合计设置，如果没有使用统一的通用设置
        },
      }),
      {},
    );

    Object.entries(totalList).forEach(([key, value]) => {
      const expense = feeCatigory.find(item => item.db_key === key);
      const totalName = '对账总金额';
      if (checked[key]) {
        // 总金额
        total_map[totalName] = {
          ...total_map[totalName],
          value: Big(total_map?.[totalName]?.value ?? 0)
            .plus(totalList[key] || 0) // plus 参数不能为空字符串
            .toFixed(2),
        };
        // 按费用类型计算合计
        if (expense) {
          const feeCategory = expense.category_name;
          total_map[feeCategory] = {
            color: '#222',
            title: feeCategory?.indexOf('合计') > 1 ? feeCategory : `${feeCategory || ''}合计`,
            ...total_map[feeCategory],
            value: Big(total_map?.[feeCategory]?.value ?? 0)
              .plus(totalList[key] || 0)
              .toFixed(2),
          };
        }
      }
    });

    const summary = expenseList?.map?.(name => total_map[name]) ?? []; // 转换成数组形式

    return summary.filter(Boolean);
  };

  _updateAmountDetail = amountDetail => {
    const amountDetailForShow = _.cloneDeep(amountDetail ?? {});

    const adjustData = this.getBillAdjustData({ stackAdjust: true });
    const adjustDetailForShow = analyzeFeeList(adjustData ?? []);

    // 账单调整的费用项要单独处理，它不属于 运单 的费用项。
    const { checked: adjustChecked, totalList: adjustTotaolList, total: adjustTotal } = adjustDetailForShow;

    amountDetailForShow.totalList = {
      ...amountDetailForShow?.totalList,
      ...adjustTotaolList,
    };
    amountDetailForShow.checked = {
      ...amountDetailForShow?.checked,
      ...adjustChecked,
    };

    try {
      const total = Big(amountDetailForShow.total).plus(adjustTotal).toFixed(2);
      amountDetailForShow.total = Number(total);
    } catch {
      amountDetailForShow.total += +adjustTotal;
    }

    this._amountDetailForShow = amountDetailForShow;
    this._adjustDetailForShow = adjustDetailForShow;

    this.updateAmountDetail(amountDetailForShow);
  };
  // 挑单页 有更新时，重新计算合计与费用明细
  // 一般是由 BiPickerOrder 触发。但是，当 isOverload 场景时，不存在 BiPickerOrder ，因此需要主动触发。
  handleChangeAmount = tableInfo => {
    const amountDetail = this.props.handleChangeAmount?.(this, { tableInfo }); // 让父组件重新调用 费用项合计 计算流程，产生新的费用项明细与合计值
    this._updateAmountDetail(amountDetail);
  };
  // 费用明细复选框事件
  handleCheckAmountDetailExpense = ({ col, record, index, checked, e, data }) => {
    const amountDetail = this.props.handleCheckAmountDetailExpense?.({ col, record, index, checked, e, data });
    // 费用明细勾选要联动合计值
    this._updateAmountDetail(amountDetail);
  };
  // 更新费用明细组件的值（需要延迟调用，保证拿到ref；同时也防止频繁调用）
  updateAmountDetail = _.debounce(amountDetail => {
    // 主动设置data，避免循环调用
    const list = this.formatAmountDetailList(amountDetail);
    const summary = this.formateAmountDetailTotal(amountDetail);
    this.amountDetailListRef?.setData?.(list);
    this.amountDetailSummaryRef?.setSummary?.(summary);

    const checkTotalInfo = summary?.find(item => item.key === 'dzzje');
    const checkTotalAmount = checkTotalInfo?.value;
    // 对账总金额值是否发生变更
    if (Number(checkTotalAmount) !== Number(this.beforeCheckTotalAmount)) {
      this.beforeCheckTotalAmount = Number(checkTotalAmount);
      this.props.onUpdateCheckTotakAmount?.(checkTotalAmount);
    }
  });

  // 获取 账单调整 费用名称 下拉
  getAdjustFeeTypeList = async () => {
    const res = await postJAVA('/cmm-finance/fee/getFeeByTypeOrOpType', { types: [17], bill_id: this.props.bill_id });
    const { billAdustInfo = {} } = this.state;
    const adjustFeeTypeList = res?.res ?? [];
    this.setState({
      adjustFeeTypeList,
      billAdustInfo: {
        ...billAdustInfo,
        enumerations: {
          ...billAdustInfo?.enumerations,
          expense:
            // 构建 Sug
            adjustFeeTypeList?.map?.(item => ({
              fee_id: item.id,
              fee_type: item.type,
              expense: item.db_key,
              key: item.db_key,
              name: item.fee_name,
              display: item.fee_name,
              ...item,
            })) ?? [],
        },
      },
    });
    return adjustFeeTypeList;
  };
  // 账单调整
  renderBillAdjust = () => {
    const billAdustInfo = this.state.billAdustInfo ?? {};
    // 默认显示一条空数据
    if (!billAdustInfo?.data?.length) {
      billAdustInfo.data = [
        {
          expense: '',
          amount: '',
        },
      ];
    }
    const { header = {} } = billAdustInfo;

    // 处理表格样式，超过一定高度显示滚动条
    const style = {}; // , showScrollbarY = false
    let height =
      (this.state.billAdjustHeight ||
        PICK_TABLE_ROW_HEIGHT * (billAdustInfo?.data?.length ?? 0) + PICK_TABLE_HEADER_HEIGHT) +
      2 +
      PICK_TABLE_HEADER_HEIGHT;
    const showKeys = Object.keys(header);
    let width = 0;
    showKeys.forEach(key => {
      const item = header[key];
      width += item.minWidth;
    });
    const wrapDom = this.cardFormHeader || this.pickOrderWrap || this.props?.getContainer?.() || window.document.body;
    const totalWidth = wrapDom.offsetWidth - 16;
    const overWidth = width > totalWidth;
    height = overWidth ? height + 16 : height;
    const list = this.setBillAdjustRow(billAdustInfo.data, billAdustInfo.enumerations ?? {});
    // 处理表格样式，超过一定高度显示滚动条
    const total = {
      amount:
        list
          ?.reduce?.((n, row) => {
            try {
              const t = Big(n).plus(row.amount);
              return t;
            } catch {
              return n;
            }
          }, 0)
          ?.toFixed(2) ?? '',
    };

    return (
      <CardBox title="账单调整" className={classnames(`${prefixCls}__adjust-container`)}>
        <div
          className={`${prefixCls}__bill_adjust`}
          style={{ ...style, width: '100%' }}
          ref={r => (this.billAdjustTableWrap = r)}
        >
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <div style={{ maxHeight: 200, height: height > 200 ? 200 : height }}>
              <Table
                key="billAdjustTable"
                tipsTrigger="click"
                classname="unedit-table-style"
                headerHeight={PICK_TABLE_HEADER_HEIGHT}
                rowHeight={PICK_TABLE_ROW_HEIGHT}
                footerHeight={26}
                data={list}
                header={header}
                enumerations={billAdustInfo.enumerations ?? {}}
                {...billAdustInfo._table}
                handleTodoAdd={this.handleBillAdjustAdd}
                handleTodoSubtract={this.handleBillAdjustSubtract}
                handleChange={this.handleBillAdjustChange}
                handleBlur={this.handleBillAdjustBlur}
                handleSelectDropSelect={this.handleBillAdjustSelect}
                total={total}
              />
            </div>
            {this.props.showAmountSummary && (
              <BiSummary ref={r => (this.amountDetailSummaryRef = r)} simple style={{ margin: '8px 0 0' }} />
            )}
          </div>
        </div>
      </CardBox>
    );
  };
  setBillAdjustData = newData => {
    const { billAdustInfo = {} } = this.state;
    this.setState({
      billAdustInfo: {
        ...billAdustInfo,
        data: newData,
      },
    });
  };
  getBillAdjustData = ({ stackAdjust = false, needAdjustFeeDirect = false, noZeroAmount = false } = {}) => {
    let adjustData = _.cloneDeep(this.state?.billAdustInfo?.data ?? []);

    if (this.props?.stackAdjust && stackAdjust && this._adjustDataFromServer) {
      this._adjustDataFromServer?.forEach?.(itemServer => {
        const itemLocal = adjustData?.find?.(item => item.expense === itemServer.expense);
        if (!itemLocal) {
          adjustData.push({ ...itemServer });
        } else {
          try {
            const total = Big(itemServer.amount).plus(itemLocal.amount).toFixed(2);
            itemLocal.amount = Number(total);
          } catch {
            itemLocal.amount += +itemServer.amount;
          }
        }
      });
    }

    adjustData = adjustData?.map?.(item => {
      // 需要费用方向，即：金额始终是正数，根据费用方向来确定收支。没有费用方向时，金额正负即收支。
      if (needAdjustFeeDirect) item.amount = Math.abs(item.amount);
      return _.omit(item, ['otherProps']);
    });

    adjustData = adjustData?.filter?.(item => (noZeroAmount ? item.amount && item.amount != 0 : true));

    return adjustData;
  };
  // 获取所有费用的详情
  getAmountDetail = () => {
    return this._amountDetailForShow;
  };
  // 获取调账的详情
  getAdjustDetail = (stackAdjust = false) => {
    const adjustData = this.getBillAdjustData({ stackAdjust });
    const adjustDetailForShow = analyzeFeeList(adjustData ?? []);
    return adjustDetailForShow;
  };
  // 账单调整 添加费用项
  handleBillAdjustAdd = () => {
    if (this.props.type === 'show') return;
    const { billAdustInfo = {}, adjustFeeTypeList = [] } = this.state;
    const { data = [] } = billAdustInfo ?? {};
    if (data?.length >= adjustFeeTypeList?.length) return;
    const newData = [
      ...data,
      {
        expense: '',
        amount: '',
      },
    ];
    this.setState(
      {
        billAdustInfo: {
          ...billAdustInfo,
          data: newData,
        },
      },
      () => {
        this.handleChangeAmount?.();
        this.props.handleBillAdjustAdd?.();
        this.props.handleChange?.('add', { newData });
      },
    );
  };
  // 账单调整 删除费用项
  handleBillAdjustSubtract = index => {
    const { billAdustInfo = {} } = this.state;
    const { data = [] } = billAdustInfo ?? {};
    const newData = [...data];
    newData.splice(index, 1);
    // 至少保留一个费用项，可以为空
    if (!newData.length) {
      newData.push({
        expense: '',
        amount: '',
      });
    }
    this.setState(
      {
        billAdustInfo: {
          ...billAdustInfo,
          data: newData,
        },
      },
      () => {
        this.handleChangeAmount?.();
        this.props.handleBillAdjustSubtract?.();
        this.props.handleChange?.('del', { index, newData });
      },
    );
  };
  // 账单调整 下拉选择
  handleBillAdjustSelect = (index, colKey, colVal) => {
    const { billAdustInfo = {} } = this.state;
    const { data = [], enumerations } = billAdustInfo ?? {};
    const newData = [...data];
    let dataRow = newData[index];
    if (colKey === 'expense') {
      const enums = enumerations?.expense ?? [];
      const feeInfo = enums?.find?.(item => item.expense === colVal);
      dataRow = {
        ...dataRow,
        fee_id: feeInfo?.id,
        fee_type: feeInfo?.type,
        expense: feeInfo?.db_key,
        fee_direct: feeInfo?.fee_direct,
        fee_name: feeInfo?.fee_name,
        share_type_name: feeInfo?.share_type_name,
      };
    } else {
      dataRow[colKey] = colVal;
    }

    newData[index] = dataRow;
    this.setRowData({
      index,
      newData,
      colKey,
      colVal,
      billAdustInfo,
      data: newData,
    });
  };
  // 账单调整金额失去焦点
  handleBillAdjustBlur = (index, colKey) => {
    const { billAdustInfo = {} } = this.state;
    const { data = [] } = billAdustInfo ?? {};
    const newData = [...data];
    const dataRow = newData[index];
    const colVal = dataRow[colKey];
    dataRow[colKey] = colVal === '-' ? '' : colVal;
    newData[index] = dataRow;
    this.setRowData({
      index,
      newData,
      colKey,
      colVal,
      billAdustInfo,
      data: newData,
    });
  };
  // 账单调整 输入数据
  handleBillAdjustChange = (index, colKey, colVal) => {
    const { billAdustInfo = {} } = this.state;
    const { data = [] } = billAdustInfo ?? {};
    const newData = [...data];
    const dataRow = newData[index];
    dataRow[colKey] = colVal;
    const { fee_direct } = dataRow;
    // 费用方向为支出时，在输入数据前添加负号
    if (fee_direct === 'out') {
      dataRow[colKey] = toNegatives(colVal);
    }
    newData[index] = dataRow;
    this.setRowData({
      index,
      newData,
      colKey,
      colVal,
      billAdustInfo,
      data: newData,
    });
  };
  setRowData = option => {
    const { index, newData, colKey, colVal, billAdustInfo, data } = option;
    this.setState(
      {
        billAdustInfo: {
          ...billAdustInfo,
          data,
        },
      },
      () => {
        this.handleChangeAmount?.();
        this.props.handleBillAdjustChange?.();
        this.props.handleChange?.('change', { index, newData, colKey, colVal });
      },
    );
  };
  // 账单调整 费用项每条数据单独设置
  setBillAdjustRow = (data = [], orignEnum) => {
    const cache = {};
    if (!data?.length) return;
    // 缓存所有已选择项
    data.forEach((item, index) => {
      cache[data[index].expense] = item;
    });

    return data.map(row => {
      const disable = ['fee_direct', 'share_type_name'];
      if (this.props.type === 'show') {
        disable.push('amount', 'expense', 'substract');
      }

      row.otherProps = {
        disable,
      };

      if (row.fee_direct) {
        const isOut = row.fee_direct === 'out';
        row.otherProps.amount = {
          pattern: isOut ? FLOAT_I_9_M_9_P_2 : FLOAT_I_9_N_9_P_2,
          // 以下两个属性是配合css使用，模拟实现自动输入负号
          shownegativesplaceholder: isOut ? '1' : '0',
          placeholder: isOut ? '-' : '',
        };
      }
      if (row.amount && +row.amount != 0) {
        row.otherProps.expense = {
          required: true,
        };
      }

      // 为每条数据单独设置枚举值，过滤掉已被选择的值
      const rowEnum = _.cloneDeep(orignEnum);
      const { expense } = row;
      const billAdjustEnum = rowEnum?.expense;
      rowEnum.expense = billAdjustEnum?.filter?.(item => item.expense === expense || !cache[item.expense]);
      row.otherProps.enumerations = rowEnum;

      return { ...row };
    });
  };
  // 账单调整格式校验
  validateBillAdjust = async ({ settleData: settleDataOuter, checkAmountWithSettle = false, opType = '审核' } = {}) => {
    const { billAdustInfo = {} } = this.state;
    const { data = [] } = billAdustInfo ?? {};

    const validRes = [];

    for (let i = 0, len = data.length; i < len; i++) {
      let validated = true;
      const tips = [];
      const row = data[i];
      if (row.amount && !row.expense) {
        validated = false;
        tips.push({ text: `账单调整项 ${i + 1} 费用名称不能为空`, block: true });
      }
      if (row.fee_direct === 'inCome' && +row.amount < 0) {
        validated = false;
        tips.push({ text: `账单调整项 ${i + 1} 收入费用只能为正数`, block: true });
      }
      if (row.fee_direct === 'out' && +row.amount > 0) {
        validated = false;
        tips.push({ text: `账单调整项 ${i + 1} 支出费用只能为负数`, block: true });
      }
      if (row.expense && +row.amount === 0) {
        tips.push({ text: `账单调整项 ${i + 1} 费用为0，请注意检查！`, block: false });
      }
      if (!row.amount && row.expense) {
        tips.push({ text: `账单调整项 ${i + 1} 费用为空，请注意检查！`, block: false });
      }
      // 有阻断式错误，直接提示
      if (!validated) {
        new PopUp(PopTip, {
          type: ERROR,
          content: tips?.[0]?.text,
        }).show();
        return validated;
      }
      validRes.push(...tips);
    }

    const continueValid = validRes.filter(item => !item.block);
    if (continueValid.length) {
      const comfirm = await confirmWithReasonSync({
        tips: `存在账单调整费用项金额为0或空，请注意检查！`, // 顶部提示语
        noticeTitle: '',
        btnText: { confirm: '继续' },
      });
      if (!comfirm) return;
    }

    const amountDetail = this._amountDetailForShow;
    const settleData = settleDataOuter ?? this.getSettleData() ?? {};

    if (amountDetail?.total !== settleData?.total && checkAmountWithSettle) {
      new PopUp(PopTip, {
        type: ERROR,
        content: `最终对账单对账合计金额与收支方式合计金额不等，不可${opType}通过!`,
      }).show();
      return;
    }
    if (
      checkAmountWithSettle &&
      (amountDetail?.total || amountDetail?.total === 0) &&
      amountDetail?.total === settleData?.total
    ) {
      return await confirmWithReasonSync({
        tips: `最终对账单对账合计金额为${amountDetail?.total}元，您确认${opType}通过吗？`, // 顶部提示语
        noticeTitle: '',
        btnText: { confirm: '确定' },
        handleHideCallback: cb => cb?.(false),
      });
    }
    return true;
  };

  // 修改收支方式
  handleLedger = ({ data }) => {
    let sum = 0;
    let sum_gas = 0;
    Object.values(data).forEach(item => {
      if (+item.amount) {
        sum = arraySum([sum, +item.amount]);
      }
      if (+item.amount_gas) {
        sum_gas = arraySum([sum_gas, +item.amount_gas]);
      }
    });

    console.log('sum =', sum, sum_gas);
    const feeInfo = this.feeSettlePaymode?.getData?.();
    const _fee = _.cloneDeep(feeInfo?.data ?? []);
    const _index = _fee?.reduce?.((pre, cur, index) => {
      return isAccountCheckPayMode(cur.pay_mode) ? [...pre, index] : pre;
    }, []);
    if (_index.length) {
      _index.forEach(index => {
        const { pay_mode } = _fee[index];
        if (carAndDriverAndCustomerCashPayModeAccountType.includes(pay_mode)) {
          // 客户账本使用正数
          _fee[index].amount = accountTypeMap.account_book_customer === pay_mode ? sum : -sum;
        }
        if (gasPayModeAccountType.includes(pay_mode)) {
          _fee[index].amount = -sum_gas;
        }
      });
    }
    const { settleInfo = {} } = this.state;
    this.setState({
      settleInfo: {
        ...settleInfo,
        feeInfo: _fee,
      },
    });
  };

  // 主动设置收支方式
  setSettleInfo = newSettleInfo => {
    const { settleInfo = {} } = this.state;
    this.setState({
      settleInfo: {
        ...settleInfo,
        ..._.cloneDeep(newSettleInfo),
      },
    });
  };

  // 主动设置账本数据
  setLedgerInfo = ledgerInfo => {
    const { settleInfo = {} } = this.state;
    this.setState({
      settleInfo: {
        ...settleInfo,
        ledgerInfo,
      },
    });
  };

  // 获取收支方式的数据
  getSettleData = () => {
    return this.feeSettlePaymode?.getData?.();
  };

  _renderSettleInfo = () => {
    const {
      multipleRow,
      feeInfo = [],
      enums = {},
      payModeDict = {},
      ledgerInfo = {},
      isFeeSubstract,
    } = this.state?.settleInfo ?? {};
    const { buttonKey, customer_type } = this.props;
    const { isFiddle } = this.state;
    let bookType = 'car';
    if (customer_type === 'customer_batch_driver') bookType = 'driver';
    if (customer_type === 'customer_batch_car') bookType = 'car';
    if (['customer_batch_project', 'customer_batch_client'].includes(customer_type)) bookType = 'customer';
    return (
      <Fragment>
        {Object.keys(ledgerInfo).length ? (
          <LedgerCard
            data={ledgerInfo}
            bookType={bookType}
            handleChange={this.handleLedger}
            ref={r => (this.ledgerWrap = r)}
          />
        ) : null}
        {!multipleRow && (
          <PayMode
            isView
            canEditAmount
            data={feeInfo}
            stateInfo={this.state?.settleInfo ?? {}}
            enumerations={enums}
            buttonKey={buttonKey}
            payModeEnum={payModeDict}
            isSubstract={isFeeSubstract}
            isFiddle={isFiddle}
            ref={r => (this.feeSettlePaymode = r)}
          />
        )}
      </Fragment>
    );
  };

  renderSettleInfo = () => {
    const {
      multipleRow,
      feeInfo = [],
      enums = {},
      payModeDict = {},
      ledgerInfo = {},
      isFeeSubstract,
    } = this.state?.settleInfo ?? {};
    const { buttonKey, customer_type } = this.props;
    return (
      <CardBox
        title={feeTitleConfig[customer_type] || '公司收款信息'}
        className={classnames(`${prefixCls}__adjust-container`)}
        header={
          <div className={`${prefixCls}__card-box--head`}>
            <Icon
              iconType="icon-help"
              tipsCls="icon-help"
              placement="top"
              tips={feeTipsConfig[customer_type] || '此处填写公司向对账对象收款时所使用的账户信息'}
            />
          </div>
        }
      >
        <div
          className={`${prefixCls}__bill_adjust`}
          style={{ width: '100%' }}
          ref={r => (this.billAdjustTableWrap = r)}
        >
          {this._renderSettleInfo()}
        </div>
      </CardBox>
    );
  };
  render() {
    return (
      <div className={classnames(`${prefixCls}`)}>
        {this.renderBillAdjust()}
        {this.props.showSettleInfo && this.renderSettleInfo()}
        {this.props.children}
      </div>
    );
  }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Load, Button, ModalDialog, SlideDrager } from 'components';
import { ERROR } from 'constants';
import { bem, fetchJAVA, showInfo } from 'utils';
import { headerHub as capitalFlowHeader } from 'components/commoncomponents/capitalFlow/constant';
import FeeBreakdown from 'components/commoncomponents/feeBreakdown';
import PayMode from 'components/commoncomponents/payMode';
import initUploadModule from '@/pages/FinanceController/UploadModule';
import { headerDict } from './constant';
import _ from 'lodash';

import { opSave } from './action';
import renderAttr from './attr';

import { prefixCls } from './index.scss';

const cls = bem(prefixCls);

export default class FeeSettle extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    showType: PropTypes.string, // 以何种形式展示，slider：侧拉，modal：弹框
    attrField: PropTypes.object, // 表单字段
    selectInfo: PropTypes.object, // 列表勾选数据信息
    completeCallBack: PropTypes.func, // 完成后回调
  };

  static defaultProps = {
    title: '结算确认',
    showType: 'slider',
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  // 切换 loading 状态
  switchLoadState = () => {
    this.setState(state => ({ isLoading: !state.isLoading }));
  };

  getInfo = async () => {
    const _self = this;
    const { customerType, selectInfo, attrField } = _self.props;
    const reqUrl = '/cmm-finance/japi/finance/repay/genPayMode';
    const req = { bill_id: +selectInfo.bill_id };
    const fetchRes = await fetchJAVA(reqUrl, { method: 'POST', body: { req } });
    if (fetchRes.errno !== 0) {
      return showInfo(ERROR, fetchRes.errmsg);
    }
    const resObj = fetchRes.res || {};
    const loadInfo = resObj.load_info || {};
    const attr = { ...attrField };
    Object.keys(attr).forEach(x => {
      attr[x].value = loadInfo[x] || '';
    });

    const listHeader = {};
    const _headerArr = _.cloneDeep(capitalFlowHeader);
    _headerArr[customerType].forEach(k => {
      listHeader[k] = {
        label: headerDict[k],
        show: true,
        disables: k !== 'amount',
        required: false,
      };
    });
    const listData =
      resObj.load_info && resObj.load_info.re_info
        ? resObj.load_info.re_info.map(x => ({ ...x, amount: x.current_settled_amount }))
        : [];
    const enums = resObj.enums || {};
    const defaultPayMode = Object.values(enums.pay_mode_info).find(x => x.default) || {};
    let feeInfo =
      resObj.load_info.pay_mode && resObj.load_info.pay_mode.length
        ? resObj.load_info.pay_mode
        : [{ ...defaultPayMode, amount: resObj.load_info.amount }];
    const payModeEditable = resObj.pay_mode_editable;
    // 数组转为 key-value pairs
    const fieldSet = (resObj.finance_set?.table_fields_setting?.value ?? []).reduce((acc, item) => {
      acc[item.key] = { ...item };
      return acc;
    }, {});
    let isFeeSubstract = true;
    if (!payModeEditable) {
      isFeeSubstract = false;
      feeInfo = feeInfo.map(x => ({ ...x, otherProps: { disable: ['pay_mode'] } }));
    }
    console.log('payModeEditable =', payModeEditable, feeInfo);
    this.setState({
      isFeeSubstract,
      enums,
      feeInfo,
      attrField: attr,
      listHeader,
      listData,
      // eslint-disable-next-line react/no-unused-state
      listTotal: Math.abs(resObj.load_info.amount),
      // eslint-disable-next-line react/no-unused-state
      settledAmount: resObj?.load_info?.re_info?.reduce?.((a, c) => a + +c.settled_amount, 0) ?? 0,
      fieldSet,
    });
  };

  attrSet = (key, val) => {
    const feeInfo = this.feeSettlePaymode.getData();
    const { attrField } = this.state;
    const attr = { ...attrField };
    attr[key].value = val;
    this.setState({ feeInfo: feeInfo.data, attrField: attr });
  };

  renderFeeBreakdown = () => {
    const { attrField, listData = [], listHeader, fieldSet } = this.state;
    const _preParam = _.cloneDeep(attrField);
    return (
      <FeeBreakdown
        data={listData}
        header={listHeader}
        isAddSub={false}
        preParam={_preParam}
        settings={fieldSet}
        ref={r => (this.feeBreakdownTable = r)}
      />
    );
  };

  // 渲染内容主体
  renderAttr = () => renderAttr(this);
  renderContent() {
    const { feeInfo = [], enums = {}, isFeeSubstract } = this.state;
    const _enum = {
      pay_mode: enums.pay_mode,
    };
    let UploadModuleEle = null;
    if (isFeeSubstract) {
      UploadModuleEle = initUploadModule.call(this, [''], '');
    }
    console.log('isFeeSubstract =', isFeeSubstract);
    return (
      <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'scroll' }}>
        {this.renderAttr()}
        {this.renderFeeBreakdown()}
        <PayMode
          isRemainAmount
          data={feeInfo}
          enumerations={_enum}
          payModeEnum={enums.pay_mode_info}
          isSubstract={isFeeSubstract}
          ref={r => (this.feeSettlePaymode = r)}
        />
        {UploadModuleEle}
      </div>
    );
  }

  close = () => {
    this.props.showType === 'slider'
      ? this.feeSettleSlider.handleHide()
      : this.feeSettleDialog && this.feeSettleDialog.handleHide();
    this.props.close && this.props.close();
  };

  handleDialogConfirm = () => {
    opSave(this);
  };

  renderFooter = () => (
    <div className="slide__drager__footer__content">
      <Button type="primary" onClick={this.handleDialogConfirm}>
        确认
      </Button>
      <Button type="default" onClick={this.close}>
        取消
      </Button>
    </div>
  );

  // 以弹框形式展示
  renderPopDialog = () => {
    const title = '结算确认';
    const popName = 'feeSettlePage';

    return (
      <ModalDialog
        isShow
        isModal
        title={title}
        popName={popName}
        content={this.renderContent()}
        bottom={this.renderFooter()}
        contentStyle={{ width: 900 }}
        handleHideCallback={this.close}
        ref={ref => (this.feeSettleDialog = ref)}
      />
    );
  };

  // 以侧拉形式展示
  renderSlider = () => {
    const title = '结算确认';
    const popName = 'feeSettlePage';

    return (
      <SlideDrager
        isShow
        popName={popName}
        slideWidth={900}
        HeaderTitle={title}
        contentDiv={this.renderContent()}
        footerDiv={this.renderFooter()}
        close={this.props.close}
        ref={r => (this.feeSettleSlider = r)}
      />
    );
  };

  render() {
    const { showType } = this.props;
    const { isLoading } = this.state;
    return (
      <Load spinning={isLoading}>
        <div className={cls()} ref={ref => (this.container = ref)}>
          {showType === 'slider' && this.renderSlider()}
          {showType === 'modal' && this.renderPopDialog()}
        </div>
      </Load>
    );
  }
}

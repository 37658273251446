/* eslint-disable no-nested-ternary */
import _, { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Table } from 'components';
import { ERROR } from 'constants';
import { bem, showInfo } from 'utils';
import { isAccountCheckPayMode, isCarAndDriverGasAccountBook } from 'pages/FinanceController/utils/accountBook';
import { prefixCls } from './index.scss';
import {
  payModeHeader,
  accountTypeSugKeyArr,
  accountTypeSugHeader,
  accountTypeFontArr,
  accountTypeFetchParams,
} from './constant';
import { formatHeader, formatData, buildFeeList, doOilAmount, doOnlyAccountBook } from './tool';

import { fuelCardSlide } from 'pages/CompanyController/FuelCard';
import BiSummary from '../pickorder/BiSummary';
import Big from 'big.js';
import getSummary, { isAudit } from './getSummary';
import { getAccountTypeFromSetting, doAccountTypeForData, resetAccountBookField } from './util';

const grayKeyArr = ['receiptPaymentSettle', 'customerSettle', 'documentSettle'];
const pageTitleArr = ['receiptOrReceiptCheck', 'expense', 'receipt'];

export default class PayMode extends PureComponent {
  static propTypes = {
    data: PropTypes.array, // 列表数据
    enumerations: PropTypes.object, // 列表枚举
    payModeEnum: PropTypes.object, // 支付方式项枚举
    maxHeight: PropTypes.number, // 容器最大高度
    minRow: PropTypes.number, // 最少行数
    maxRow: PropTypes.number, // 最大行数
    isSubstract: PropTypes.bool, // 是否显示加减号
    isAddOilCard: PropTypes.bool, // 标题是否显示油卡 Icon
    handleBlur: PropTypes.func, // 单元格blur回调
    handleChange: PropTypes.func, // 单元格值变化回调
    handleSelectDropSelect: PropTypes.func, // 特殊列的selectDrop
    handleToDoSubtract: PropTypes.func, // 删除行回调
    handleToDoAdd: PropTypes.func, // 增加行回调
    isView: PropTypes.bool, // 是否只是查看
    stateInfo: PropTypes.object, // 标题规则
    postData: PropTypes.object, // 接收的页面的经办人、司机、车辆数据
    openerPageType: PropTypes.string, // 来源页的pageType
  };

  static defaultProps = {
    minRow: 0,
    isView: false,
    isSubstract: true,
    isAddOilCard: false,
    canEditAmount: false, // 是否可编辑金额字段（账本除外）
    openerPageType: '',
    postData: {},
  };

  static contextTypes = {
    hanldeResizeTableWidth: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const _props = this.props;
    const data = _props.data && _props.data.length ? _props.data : [];
    console.log('constructor data', data);

    const canUseGroupAccount = ['expense'].includes(props.openerPageType); // 目前只有 收支汇总-报销单 支持集团账本

    const isShowNewPayMode = _props.openerPageType && pageTitleArr.includes(_props.openerPageType); // 新加的3中支付方式
    const header = this.getHeader(_props);
    const needGray = grayKeyArr.includes(_props.openerPageType); // 司机报销单、付款单页面来源的3种新增支付方式需要置灰
    const _data = formatData(data, header, _props.isView, {
      canEditAmount: props.canEditAmount,
      auditType: _props.auditType,
      needGray,
    });
    this.state = {
      header,
      data: _data,
      enumerations: _props.enumerations,
      payModeEnum: _props.payModeEnum,
      // eslint-disable-next-line react/no-unused-state
      isSubstract: _props.isSubstract,
      minRow: _props.minRow,
      maxRow: _props.maxRow,
      // eslint-disable-next-line react/no-unused-state
      isTaskAmount: _props.buttonKey, // 任务对账单-结算弹窗
      amount: 0,
      accountAmount: 0,
      otherAmount: 0,
      residueAmount: 0,
      postData: _props.postData,
      isShowNewPayMode,
      // amountInfo: _props.stateInfo
      isFiddle: props.isFiddle,
      canUseGroupAccount,
    };

    // 收支合计
    this._summary = getSummary(this.props.stateInfo?.customer_type, props.buttonKey);

    // 获取账本类型配置
    canUseGroupAccount && this.getAccountTypeSetting?.();
  }

  // componentWillUnmount() {
  // this.setState = () => {}
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const preProps = this.props;
    if (!_.isEqual(preProps.data, nextProps.data)) {
      const needGray = grayKeyArr.includes(this.props.openerPageType); // 司机报销单、付款单页面来源的3种新增支付方式需要置灰
      const newData = formatData(nextProps.data, this.getHeader(nextProps), this.props.isView, {
        canEditAmount: nextProps.canEditAmount,
        auditType: this.props.auditType,
        needGray,
      });
      this.setState(
        {
          data: newData,
        },
        () => this.doAmount(newData),
      );
    }
    if (!_.isEqual(preProps.enumerations, nextProps.enumerations)) {
      this.setState({ enumerations: { ...nextProps.enumerations } });
    }

    if (!_.isEqual(preProps.payModeEnum, nextProps.payModeEnum)) {
      this.setState({ payModeEnum: { ...nextProps.payModeEnum } });
    }
    if (!_.isEqual(preProps.postData, nextProps.postData)) {
      // 切换网点和经办人时需要清空支付
      if (
        preProps.postData.create_by !== nextProps.postData.create_by ||
        preProps.postData.com_id !== nextProps.postData.com_id
      ) {
        this.setState({
          data: formatData(nextProps.data, this.getHeader(nextProps), this.props.isView, {
            canEditAmount: nextProps.canEditAmount,
            auditType: this.props.auditType,
          }),
          postData: { ...nextProps.postData },
        });
      } else {
        this.setState({ postData: { ...nextProps.postData } });
      }
    }
    if (preProps.minRow !== nextProps.minRow) this.setState({ minRow: { ...nextProps.minRow } });
    if (preProps.maxRow !== nextProps.maxRow) this.setState({ maxRow: { ...nextProps.maxRow } });
    if (preProps.isSubstract !== nextProps.isSubstract) this.setState({ header: this.getHeader(nextProps) });
    if (preProps.stateInfo?.settle_amount_unsettle !== nextProps.stateInfo?.settle_amount_unsettle) {
      this.updateSummary({ unsettleTotal: nextProps.stateInfo?.settle_amount_unsettle });
    }
  }

  getAccountTypeSetting = async () => {
    const accountSetting = await getAccountTypeFromSetting();
    this.accountSetting = accountSetting;
  };

  // 重置账本账户字段，并且标红必填
  // 有两个场景需要重置：1. 创建时是集团账本，修改时是网点账本 2. 刚进入页面是集团账本，提交时配置改成了网点账本，
  resetAccountBookField = names => {
    resetAccountBookField(this, { names, dataKey: 'data', payModeWrap: this.payModeWrap });
  };

  getHeader = prop => {
    const _hd = _.cloneDeep(payModeHeader);
    const isShowNewPayMode = prop.openerPageType && pageTitleArr.includes(prop.openerPageType); // 新加的3中支付方式
    const account_book_card = {
      title: '账本账户',
      display: 'show',
      width: 120,
      flexGrow: 2,
      type: 'SelectDrop',
      showKey: 'account_book_company_book_name',
      showHeader: true,
      tableHeader: _.cloneDeep(accountTypeSugHeader),
      tableEmptyTip: '无推荐账本，请通过筛选框筛选',
      tableHeaderTip: '根据单据中涉及的提报人、车辆、司机，推荐如下；更多账本请通过筛选框筛选',
      fetchApi: {
        url: 'Finance/Finance/accountBookSug',
        para: { page_size: 50 },
      },
    };
    if (isShowNewPayMode) {
      _hd.account_book_card = account_book_card;
    }
    if (!prop.isSubstract) delete _hd.substract;
    return _hd;
  };

  getData = () => {
    const rowList = this.payModeTable.getStateCache();
    return buildFeeList(rowList);
  };

  getInitRow = () => {
    const row = {};
    const _header = this.state.header;
    Object.keys(_header).forEach(k => {
      if (k !== 'substract') row[k] = '';
    });
    row.otherProps = {
      disable: [],
    };
    return [row];
  };

  handleState = (index, colKey, value, eventType) => {
    const { data = [], payModeEnum = {}, amountInfo, header, canUseGroupAccount } = this.state;
    const _data = _.cloneDeep(data);
    const _amountInfo = _.cloneDeep(amountInfo);
    _data[index][colKey] = value;
    const _header = _.cloneDeep(header);
    if (colKey === 'pay_mode' && payModeEnum[value[0].key]) {
      const dict = payModeEnum[value[0].key];
      const payMode = value[0].name;
      // 油卡号码、账本账户的禁用状态
      this.doOtherProps(payMode, _header, _data[index]);
      Object.keys(_header).forEach(k => {
        if (k !== 'pay_mode' && k !== 'amount') _data[index][k] = dict[k] || '';
        if (k === 'oil_card')
          _data[index][k] = [{ id: dict.oil_card_id, card_num: dict.oil_card, is_relation: dict.is_relation }];
      });
    }
    // _data[index].pay_mode 默认是字符串，手动切换变成数组
    const payMode = _.get(_data, `[${index}].pay_mode[0].key`) || _.get(_data, `[${index}].pay_mode`);
    // 切换油卡后 is_relation 和收支方式保持一致
    if (colKey === 'oil_card' && value[0]) {
      const is_relation = _.get(payModeEnum, `[${payMode}].is_relation`);
      const mark = doOilAmount(_data[index].amount, value[0].balance, is_relation);
      if (mark) {
        _.set(_data, `[${index}][${colKey}][0].is_relation`, _.get(payModeEnum, `[${payMode}].is_relation`));
      } else {
        _data[index].oil_card = '';
      }
    }

    // 填金额失去焦点时
    if (eventType === 'handleBlur' && colKey === 'amount') {
      const is_relation = _data[index]?.oil_card?.[0]?.is_relation;
      const balance = _data[index]?.oil_card?.[0]?.balance;
      const mark = doOilAmount(value, balance, is_relation);
      if (!mark) {
        _data[index].amount = '';
      }
      this.doAmount(_data);
    }

    // 选完支付方式
    if (eventType === 'handleSelectDropSelect' && colKey === 'pay_mode') {
      this.doAmount(_data);
    }

    // 同一个司机、车辆、员工账本的账本账户只能选择一条
    if (eventType === 'handleSelectDropSelect' && colKey === 'account_book_card') {
      const cardId = value?.[0]?.account_book_uuid;
      const mark = doOnlyAccountBook(_data, payMode, cardId);
      if (!mark) {
        const emptyObj = {};
        accountTypeSugKeyArr.forEach(keyItem => {
          if (keyItem === 'account_book_is_suggest') {
            emptyObj[keyItem] = 0;
          } else {
            emptyObj[keyItem] = '';
          }
        });
        _data[index].account_book_card = [emptyObj];
      }
      canUseGroupAccount && _.set(_data, [index, 'is_group_account'], this.accountSetting?.[payMode]);
    }

    this.setState({ data: _data, header: _header, amountInfo: _amountInfo });
  };
  // 处理油卡号码和账本账户的显隐关系
  doOtherProps = (payMode, _header, data) => {
    if (accountTypeFontArr.includes(payMode)) {
      const arr = [
        'bank_name',
        'bank_num',
        'account_holder',
        'cheque_no',
        'draft_no',
        'wechat_no',
        'alipay_no',
        'oil_card',
      ];
      data.otherProps.disable = data.otherProps.disable.concat(arr);
      _.remove(data.otherProps.disable, function (x) {
        return x === 'account_book_card';
      });
    } else {
      data.otherProps.disable = [];
      data.otherProps.disable.push('account_book_card');
    }
  };
  // 处理金额交互
  doAmount = data => {
    let accountAmount = Big(0);
    let otherAmount = Big(0);
    let residueAmount = Big(0);
    try {
      // eslint-disable-next-line array-callback-return
      data?.map?.(item => {
        if (item.amount) {
          const payMode = Array.isArray(item.pay_mode) ? item.pay_mode[0].name : item.pay_mode;
          if (accountTypeFontArr.includes(payMode)) {
            accountAmount = accountAmount?.plus?.(item.amount);
          } else {
            otherAmount = otherAmount?.plus?.(item.amount);
          }
        }
      });
      const { amount } = this.state;
      residueAmount = Big(amount)?.minus?.(accountAmount)?.minus?.(otherAmount);
    } catch (error) {
      console.error(error);
    }

    if (this.state.isShowNewPayMode) {
      this.setState({
        accountAmount: accountAmount?.toFixed?.(2),
        otherAmount: otherAmount?.toFixed?.(2),
        residueAmount: residueAmount?.toFixed?.(2),
      });
    }

    this?.biFeeSummaryRef?.setSummary?.(this.getSummaryForShow?.());
  };

  // 特殊列的 selectDrop 值变化回调
  handleSelectDropSelect = (index, colKey, value, rowData) => {
    this.handleState(index, colKey, value, 'handleSelectDropSelect');
    this.props.handleSelectDropSelect && this.props.handleSelectDropSelect(index, colKey, value, rowData);
  };
  doSelectParaCallback = (payMode, _header) => {
    if (accountTypeFontArr.includes(payMode)) {
      _header.account_book_card.fetchApi.paraCallback = (para, key, keyword) => {
        const { postData } = this.state;
        const { re_info } = postData;
        let { create_by, com_id } = postData;

        // 修复ET，入参使用 ID 字符串，
        if (isArray(create_by)) {
          create_by = create_by?.[0]?.id ?? create_by;
        }
        if (isArray(com_id)) {
          com_id = com_id?.[0]?.id ?? com_id;
        }

        return {
          ...para,
          create_by,
          re_info,
          company_id: com_id,
          search: keyword,
          type: accountTypeFetchParams[payMode],
          need_group_account: this.accountSetting?.[payMode],
        };
      };
      this.setState({ header: _.cloneDeep(_header) });
    }
  };
  handleSelectFocus = (index, colKey) => {
    // console.log('handleSelectFocus', index, colKey);
    if (colKey === 'account_book_card') {
      const { data = [], header } = this.state;
      const _data = _.cloneDeep(data);
      const _header = _.cloneDeep(header);
      const payMode = _.get(_data, `[${index}].pay_mode[0].key`) || _.get(_data, `[${index}].pay_mode`);
      // 更改余额显示title
      if (isCarAndDriverGasAccountBook(payMode)) {
        _header.account_book_card?.tableHeader?.forEach(item => {
          if (item.key === 'account_book_balance') {
            item.title = `${payMode}余额`;
          }
        });
      }
      this.doSelectParaCallback(payMode, _header);
    }
  };

  // 单元格值变化回调
  handleChange = (index, colKey, value, rowData) => {
    if (colKey !== 'amount') {
      this.handleState(index, colKey, value, 'handleChange');
      this.props.handleChange && this.props.handleChange(index, colKey, value, rowData);
    }
  };

  // 单元格blur回调
  handleBlur = (index, colKey, value, rowData) => {
    this.handleState(index, colKey, value, 'handleBlur');
    this.props.handleBlur && this.props.handleBlur(index, colKey, value, rowData);
  };

  // 表头加号
  handleTodoAdd = () => {
    const { maxRow } = this.state;
    const { data = [] } = this.state;
    const _data = _.cloneDeep(data);
    if (maxRow && _data.length === maxRow) return showInfo(ERROR, `最多有${maxRow}行数据！`);
    const initRow = this.getInitRow();
    const _list = [..._data, ...initRow];
    this.setState({ data: _list });
    this.props.handleToDoAdd && this.props.handleToDoAdd(_list);
  };

  // 数据行减号
  handleTodoSubtract = index => {
    const { minRow } = this.state;
    const { data = [] } = this.state;
    const _data = _.cloneDeep(data);
    if (minRow && _data.length === minRow) return showInfo(ERROR, `最少有${minRow}行数据！`);
    _data.splice(index, 1);
    // 删行时需要处理金额
    this.doAmount(_data);
    this.setState({ data: _data });
    this.props.handleToDoSubtract && this.props.handleToDoSubtract(index);
  };

  addOilCard = () => {
    fuelCardSlide(null, 'fc_add', null, v => {
      if (v.errno !== 0) return showInfo(ERROR, '最少要有一行单据');
    });
  };

  // 更新收支方式合计
  updateSummary = sums => {
    const { _summary } = this;

    Object.entries(sums ?? {}).forEach(([key, value]) => {
      const index = _summary?.findIndex?.(item => item.key === key);
      if (index > -1) {
        const cTotal = _summary?.[index]?.value ?? 0;
        try {
          _.set(_summary, [index, 'value'], Big(value ?? cTotal)?.toFixed?.(2) ?? cTotal); // 单据金额合计
        } catch (e) {
          console.log(e);
        }
      }
    });

    Promise.resolve().then(() => this.biFeeSummaryRef?.setSummary?.(this.getSummaryForShow()));
  };

  // 从收支方式列表获取 收支合计
  getSummaryForShow = () => {
    const { _summary } = this;
    const feeData = this.payModeTable?.getTableData?.() ?? {};
    // 计算各部分合计值
    let bookTotal = Big(0);
    let otherTotal = Big(0);
    let total = Big(0);

    const unsettleTotalItem = _summary?.find(item => item.key === 'unsettleTotal');
    const unsettleTotal = Big(unsettleTotalItem?.value || 0); // 待结算金额

    const billTotalItem = _summary?.find(item => item.key === 'billTotal');
    const billTotal = Big(billTotalItem?.value || 0); // 单据合计金额

    feeData?.data?.forEach?.(item => {
      try {
        if (isAccountCheckPayMode(item.pay_mode)) {
          bookTotal = Big(bookTotal)?.plus?.(item.amount || 0);
        } else {
          otherTotal = Big(otherTotal)?.plus?.(item.amount || 0);
        }
        total = Big(total)?.plus?.(item.amount || 0);
      } catch (e) {
        console.log(e);
      }
    });
    const totalValue = this.props.buttonKey && isAudit(this.props.buttonKey) ? billTotal : unsettleTotal;
    const remainTotal = totalValue?.minus?.(total);
    Object.entries({ billTotal, unsettleTotal, bookTotal, otherTotal, remainTotal }).forEach(([key, value]) => {
      const index = _summary?.findIndex(item => item.key === key);
      if (index > -1) _.set(_summary, [index, 'value'], value?.toFixed?.(2)); // 单据金额合计
    });
    return _summary;
  };

  render() {
    const { isView, stateInfo = {}, buttonKey, openerPageType } = this.props;
    const {
      header = {},
      data = [],
      enumerations = {},
      accountAmount = 0,
      otherAmount = 0,
      residueAmount,
      postData = {},
      isShowNewPayMode = false,
      isFiddle,
    } = this.state;
    let { amount } = this.state;
    try {
      amount = Big(amount)?.toFixed?.(2);
    } catch (error) {
      console.error(error);
    }

    if (!Object.keys(header).length) return null;
    const _header = formatHeader(header, data, this, buttonKey);
    doAccountTypeForData(header, data, this.accountSetting);
    const height = 32 * data.length + 36 + 2;
    const { maxHeight = 200 } = this.props;
    const _height = height > maxHeight ? maxHeight : height;
    const cls = bem(prefixCls);
    const feeRelationAndFeeOuts =
      stateInfo.fee_relation_and_fee_outs?.filter(filterItem => !!filterItem.category_amount) || [];
    // 新加的3中支付方式才展示总额部分
    if (isShowNewPayMode) {
      const docData = postData.re_info ?? [];
      const propsAmount = docData?.reduce?.((total, item) => +(total + parseFloat(item.amount, 10)).toFixed(2), 0);
      //  ? '报销单' : '收据'
      this.setState({ amount: openerPageType === 'expense' ? -propsAmount : propsAmount });
      this.doAmount(data);
    }
    return (
      <div className={cls()} ref={r => (this.payModeWrap = r)}>
        {!isFiddle && !!stateInfo.fee_relation_and_fee_outs && (
          <div style={{ margin: '8px', fontSize: '14px' }}>
            <span style={{ fontSize: '13px' }}>实付金额</span>
            <span style={{ fontSize: '18px', color: '#D9001B', fontWeight: '650' }}>{stateInfo.bill_amount}</span>元
            {!!feeRelationAndFeeOuts.length && '('}
            {feeRelationAndFeeOuts?.map((item, index) => {
              return (
                <span>
                  {item.category_name}
                  {item.category_amount}
                  {index < feeRelationAndFeeOuts.length - 1 ? ' - ' : ''}
                </span>
              );
            })}
            {!!feeRelationAndFeeOuts.length && ')'}
          </div>
        )}
        {isView || !isShowNewPayMode ? (
          ''
        ) : !isFiddle ? (
          <div className="amount-area">
            单据金额合计：{amount}元 账本抵扣金额合计：{accountAmount}元 其他收支方式金额合计：{otherAmount}元 剩余：
            {residueAmount}元
          </div>
        ) : null}
        {/* 我在大战嘞，没时间去抽象复用上面这段逻辑了 */}
        {isFiddle ? (
          <BiSummary
            ref={r => (this.biFeeSummaryRef = r)}
            simple
            style={{ marginRight: 'auto', width: '100%', margin: '1px 0 0 0', height: 42 }}
            summary={this.getSummaryForShow()}
          />
        ) : null}
        <div style={{ width: '100%' }}>
          <div style={{ maxHeight, height: _height }}>
            <Table
              key="payModeTable"
              classname="pay-mode-table"
              rowHeight={32}
              headerHeight={36}
              enumerations={enumerations}
              // header={this.hanldeResizeTableWidth(_header)}
              header={_header}
              isAutoContentWidth
              data={[...data]}
              isShowSort={false}
              isRowSelect={false}
              canFreeClumn={false}
              isShowTotalRow={false}
              isShowFilterRow={false}
              canSaveTableStyle={false}
              handleFocus={this.handleSelectFocus}
              handleSelectDropSelect={this.handleSelectDropSelect}
              handleTodoSubtract={this.handleTodoSubtract}
              handleTodoAdd={this.handleTodoAdd}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              refGetter={r => (this.payModeTable = r)}
              // isAutoContentHeight
              showScrollbarY
            />
          </div>
        </div>
      </div>
    );
  }
}
